import styled from "styled-components";
import { rgba } from "polished";

import * as C from "../commons/styles";

export const Logo = styled.div`
  a {
    display: block;
  }
  svg {
    width: 150px;
    height: auto;

    transition: width 0.2s ease-in;
  }

  @media (max-width: 768px) {
    svg {
      width: 75px;
    }
  }
`;

export const Wrapper = styled.header`
  margin: 50px 20px;
  z-index: 2;

  .bm-burger-button {
    position: fixed;
    left: 20px;
    top: 20px;
    padding: 5px 0;
    width: 30px;
    height: 20px;
  }

  .bm-burger-bars {
    height: 3px !important;
    background: ${({ theme }) => theme.blue};
  }
  .bm-burger-bars-hover {
    background: ${({ theme }) => rgba(theme.blue, 0.39)};
  }

  .bm-cross-button {
    top: 20px !important;
    right: 20px !important;
    height: 30px;
    width: 20px;
  }

  .bm-cross {
    position: absolute;
    width: 2px !important;
    height: 20px !important;
    left: 7px;
    top: -2px;
    background: ${({ theme }) => theme.white};
  }

  .bm-menu-wrap {
    position: fixed;
    height: 100%;
    top: 0;
  }

  .bm-menu {
    padding: 70px 40px 40px 40px;
    background: ${({ theme }) => theme.blue};
  }

  .bm-morph-shape {
    fill: #373a47;
  }

  .bm-item-list {
    display: flex;
    flex-direction: column;
  }

  .bm-item {
    display: flex;
    flex-direction: row;
    padding: 5px 0;

    a {
      font-size: 25px;
      color: ${({ theme }) => rgba(theme.white, 0.39)};
      text-decoration: none;

      &.saga {
        font: normal 30px / 1 "Permanent Marker";
      }
    }

    svg {
      width: 30px;
      margin-right: 10px;
      path {
        stroke: ${({ theme }) => rgba(theme.white, 0.39)};
      }
    }
  }

  .bm-overlay {
    top: 0;
    left: 0;
    background: ${({ theme }) => rgba(theme.blue, 0.39)};
  }

  @media (max-width: 768px) {
    position: fixed;
    top: 0;
    margin: 0;
    padding: 15px 20px 5px 20px;
    width: 100%;
    background: ${({ theme }) => theme.white};

    &.sticky ${Logo} svg {
      width: 50px;
    }
  }
`;

export const Content = styled(C.Content)`
  align-items: center;
`;

export const NavLeft = styled.div`
  flex: 1;
  ul {
    display: flex;
  }

  li {
    margin-right: 45px;
    font-size: 20px;
    line-height: 30px;
    text-transform: uppercase;
    &:last-child {
      margin-right: 0;
    }
    a {
      color: ${({ theme }) => theme.blue};
      text-decoration: none;
      transition: color 0.2s;

      &:hover,
      &.active {
        color: ${({ theme }) => rgba(theme.blue, 0.39)};
      }
    }
  }
`;

export const NavRight = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  ul {
    display: flex;
    justify-content: flex-end;
  }
  li {
    margin-left: 45px;
    font-size: 20px;
    line-height: 30px;
    text-transform: uppercase;
    a {
      color: ${({ theme }) => theme.blue};
      text-decoration: none;
      transition: color 0.2s;

      &:hover,
      &.active {
        color: ${({ theme }) => rgba(theme.blue, 0.39)};
      }
      svg {
        height: 20px;
        path {
          transition: opacity 0.2s;
        }
      }
      &:hover svg,
      &.active {
        opacity: 0.39;
      }
    }

    a.saga {
      font: normal 30px / 1 "Permanent Marker";
      color: ${({ theme }) => theme.blue};
      text-decoration: none;
      transition: color 0.2s;

      &:hover,
      &.active {
        color: ${({ theme }) => rgba(theme.blue, 0.39)};
      }
    }

    a.podcast {
      display: block;
      margin-bottom: 40px;
      font-size: 20px;
      line-height: 30px;
      transition: color 0.2s;
      transform: rotate(-3deg) translateY(5px);
      transform-origin: bottom left;
      text-decoration: none;
      color: ${({ theme }) => theme.blue};
      &:hover,
      &.active {
        color: ${({ theme }) => rgba(theme.blue, 0.39)};
      }
    }
  }
`;
