const Study = {
  chapters: [
    {
      start: 1,
      end: 7,
      title: "Introduction",
    },
    {
      start: 8,
      end: 17,
      title: "De la démesure",
    },
    {
      start: 18,
      end: 26,
      title: "De la couleur",
    },
    {
      start: 27,
      end: 36,
      title: "De l'ingéniosité",
    },
    {
      start: 37,
      end: 51,
      title: "De l'inutile",
    },
  ],
  images: [
    "/images/study/01.jpg",
    "/images/study/02.jpg",
    "/images/study/03.jpg",
    "/images/study/04.jpg",
    "/images/study/05.jpg",
    "/images/study/06.jpg",
    "/images/study/07.jpg",
    "/images/study/08.jpg",
    "/images/study/09.jpg",
    "/images/study/10.jpg",
    "/images/study/11.jpg",
    "/images/study/12.jpg",
    "/images/study/13.jpg",
    "/images/study/14.jpg",
    "/images/study/15.jpg",
    "/images/study/16.jpg",
    "/images/study/17.jpg",
    "/images/study/18.jpg",
    "/images/study/19.jpg",
    "/images/study/20.jpg",
    "/images/study/21.jpg",
    "/images/study/22.jpg",
    "/images/study/23.jpg",
    "/images/study/24.jpg",
    "/images/study/25.jpg",
    "/images/study/26.jpg",
    "/images/study/27.jpg",
    "/images/study/28.jpg",
    "/images/study/29.jpg",
    "/images/study/30.jpg",
    "/images/study/31.jpg",
    "/images/study/32.jpg",
    "/images/study/33.jpg",
    "/images/study/34.jpg",
    "/images/study/35.jpg",
    "/images/study/36.jpg",
    "/images/study/37.jpg",
    "/images/study/38.jpg",
    "/images/study/39.jpg",
    "/images/study/40.jpg",
    "/images/study/41.jpg",
    "/images/study/42.jpg",
    "/images/study/43.jpg",
    "/images/study/44.jpg",
    "/images/study/45.jpg",
    "/images/study/46.jpg",
    "/images/study/47.jpg",
    "/images/study/48.jpg",
    "/images/study/49.jpg",
    "/images/study/50.jpg",
    "/images/study/51.jpg",
  ],
  stories: [
    "panda",
    "ultrafragola",
    "pipistrello",
    "crash",
    "evo-c",
    "hortensia",
    "imac",
    "piccy",
    "tapisserie",
    "gigogne",
    "serpentone",
    "putrella",
    "bellhop",
  ],
};

export default Study;
