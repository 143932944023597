import styled from "styled-components";

export const Wrapper = styled.section`
  position: relative;
  margin: 0;
  padding: 40px 120px 100px 120px;
  color: ${({ theme }) => theme.blue};
  background: ${({ theme }) => theme.white};
  flex-direction: row;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 30px 40px;

    h2 {
      margin: 0;
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  text-align: left;

  h2 {
    text-align: left;
    margin-bottom: 32px;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
  }

  p {
    flex: 1;
    margin: 0 130px 0 0;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
  }

  a {
    color: ${({ theme }) => theme.blue};
    &:hover {
      text-decoration: none;
    }
  }

  @media (max-width: 768px) {
    h2 {
      margin: 0;
      font-size: 14px;
      line-height: 21px;
    }
  }
`;

export const Stories = styled.div`
  flex: 1;
  max-width: 360px;
  text-align: center;
`;

export const List = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin-bottom: 30px;
  z-index: 2;

  @media (max-width: 768px) {
    justify-content: center;
    margin: 30px 0 0 0;
  }
`;

export const Close = styled.span`
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 10px;

  svg {
    width: 40px;
  }

  @media (max-width: 768px) {
    top: -10px;
    right: -10px;
    svg {
      width: 25px;
    }
  }
`;

export const Podcast = styled.div`
  max-width: 1200px;
  margin: 40px auto 0 auto;
`;
