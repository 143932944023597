import styled from "styled-components";

import * as C from "../../components/commons/styles";

export const Wrapper = styled(C.Content)`
  margin: 55px auto 200px auto;
  flex-direction: column;
  text-align: center;

  iframe {
    margin: 0 auto;
  }

  @media (max-width: 768px) {
    margin: 20px;
  }
`;

export const T = styled.div``;
