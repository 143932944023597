import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";

import MakersIcon from "../../images/Maker";
import EyeIcon from "../../images/Eye";

import * as S from "./styles";

const Item = ({ product, title = true }) => {
  return (
    <S.Wrapper to={`/shop/${product.slug}`}>
      <S.Preview>
        <img
          src={product.images[0]}
          alt={`${product.title} - ${product.designer.name}`}
        />
        <S.Flocking>
          <MakersIcon />
        </S.Flocking>
        <S.Hover>
          <EyeIcon />
        </S.Hover>
      </S.Preview>
      {title && (
        <S.Title>
          {product.title}, {product.designer.name}
        </S.Title>
      )}
    </S.Wrapper>
  );
};

Item.propTypes = {
  product: PropTypes.object.isRequired,
  title: PropTypes.bool,
};

export default Item;
