import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";

import Layout from "../../components/Layout";

import * as S from "./styles";

const PRICES = [
  {
    count: 3,
    title: "Une carte postale personnalisée avec un dessin de designer",
  },
  {
    count: 5,
    title: "Une citation dans la Newsletter d'un projet, d'un portfolio, d'un évènement",
  },
  {
    count: 15,
    title: "Un dessin de designer, format A5",
  },
  {
    count: 40,
    title: "Une entrée dans le Club de Punktional",
  },
  {
    count: 100,
    title: "Une montre Swatch",
  },
  {
    count: 1000,
    title: "Une chaise Eames DSW",
  },
];

const ReferralProgram = () => {
  const [isCopied, setIsCopied] = useState({ link: false, msg: false });
  const { referrerId } = useParams();

  const handleFocus = (event) => {
    event.preventDefault();
    const range = document.createRange();
    const sel = window.getSelection();
    range.selectNodeContents(event.target);
    sel.removeAllRanges();
    sel.addRange(range);
  };

  const handleFocusInput = (event) => event.target.select();

  const handleCopied = (kind) => {
    setIsCopied({ ...isCopied, ...{ [kind]: true } });
  };

  useEffect(() => {
    const timers = [];
    for (const [kind, copied] of Object.entries(isCopied)) {
      if (copied)
        timers.push(
          setTimeout(() => {
            setIsCopied({ ...isCopied, ...{ [kind]: false } });
          }, 2 * 1000)
        );
    }

    return () => {
      for (const timer of timers) window.clearTimeout(timer);
    };
  }, [isCopied]);

  const customLink = referrerId
    ? `https://punktional.com/newsletter/${referrerId}`
    : undefined;

  return (
    <Layout subFooter={false} newsletter={false}>
      <Helmet>
        <title>Punktional - Programme de parrainage</title>
      </Helmet>
      <S.Wrapper>
        <h1>Partagez la newsletter</h1>

        <S.Prices>
          <S.PricesContent>
            <h2>Abonnez 10 personnes ou plus, et gagnez 1 livre de design 🎁</h2>
            <img src="/images/newsletter/cadeau.jpg" alt="+ 10 abonné.e.s = 1 livre" />
            {/* <S.List>
              {PRICES.map((price) => (
                <S.Price>
                  <S.Counter>{price.count}</S.Counter>
                  {price.title}
                </S.Price>
              ))}
            </S.List> */}
          </S.PricesContent>
        </S.Prices>

        <S.Content>
          <h2>Comment ça marche ?</h2>
          <ul>
            {referrerId ? (
              <>
                <li>
                  Partagez votre lien personnalisé par e-mail,
                  whatsapp, linkedin, etc.
                  <S.Form>
                    <S.Input
                      type="text"
                      value={customLink}
                      onFocus={handleFocusInput}
                    />
                    <CopyToClipboard
                      text={customLink}
                      onCopy={() => handleCopied("link")}
                    >
                      <S.CopyLink>
                        {isCopied.link ? "Copié" : "Copier le lien"}
                      </S.CopyLink>
                    </CopyToClipboard>
                  </S.Form>
                </li>
              </>
            ) : (
              <>
                <li>
                  Prérequis : être abonné.e à la Newsletter
                  <S.Link to="/newsletter">Je m'abonne</S.Link>
                </li>
                <li>
                  Dans la newsletter que vous recevez, il y a en bas de l’email
                  un lien personnalisé. Il suffit de le copier et de le partager
                  par e-mail, whatsapp, etc.
                </li>
              </>
            )}
            <li>
              Si besoin, voici un texte à copier-coller :
              <S.Copy onClick={handleFocus}>
                Je suis fan de la newsletter de Punktional et j’ai pensé que ça
                te plairait (si ce n’est pas déjà le cas).
                <br />
                Toutes les deux semaines, c’est une analyse illustrée d'un objet ou d'un
                service pour aiguiser notre regard sur le design.
                <br />
                <br />
                Abonne-toi ici :
                {referrerId ? ` ${customLink}` : ` [Copie-colle ton lien]`}
              </S.Copy>
              <CopyToClipboard
                text={`Je suis fan de la newsletter de Punktional et j’ai pensé que ça te plairait (si ce n’est pas déjà le cas).
Toutes les deux semaines, c’est une analyse illustrée d'un objet ou d'un service pour aiguiser notre regard sur le design.

Abonne-toi ici : ${referrerId ? customLink : "[Copie-colle ton lien]"}`}
                onCopy={() => handleCopied("msg")}
              >
                <S.CopyMsg>
                  {isCopied.msg ? "Copié" : "Copier le message"}
                </S.CopyMsg>
              </CopyToClipboard>
            </li>
            <li>
              Lorsque vous atteigniez le palier, on vous contacte par
              e-mail pour vous envoyer votre récompense.
            </li>
          </ul>
        </S.Content>
      </S.Wrapper>
    </Layout>
  );
};

export default ReferralProgram;
