import styled from "styled-components";

import * as C from "../../components/commons/styles";

export const Wrapper = styled(C.Content)`
  margin: 100px auto;
  flex-direction: column;

  @media (max-width: 1280px) {
    padding: 0 35px;
  }
  @media (max-width: 768px) {
    margin: 20px 0;
    padding: 0;
  }

  .withImg {
    display: inline-block;
    img {
      float: left;
      width: 50%;
      margin: 0 20px 20px 0;

      &.right {
        float: right;
        margin: 0 0 20px 20px;
      }
    }
  }

  .images {
    display: flex;
    margin: 20px 0 50px 0;
    img {
      width: 100%;
    }
  }
`;

export const Title = styled.h1`
  margin: 0 0 37px 0;
  font: normal 36px / 52 "Permanent Marker";
  color: ${({ theme }) => theme.blue};
  text-align: center;

  @media (max-width: 768px) {
    font-size: 22px !important;
    line-height: 32px !important;
  }
`;

export const Category = styled.section`
  margin-top: 100px;
  &:first-of-type {
    margin-top: 0;
  }
`;

export const SubTitle = styled.h2`
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;

  @media (max-width: 768px) {
    padding: 0 20px;
  }
`;

export const Intro = styled.h3`
  max-width: 750px;

  @media (max-width: 768px) {
    padding: 0 20px;
    font-size: 16px;
    line-height: 1.1em;
  }
`;
