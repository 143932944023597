import styled from "styled-components";
import { rgba } from "polished";
import { Link as UnstyledLink } from "react-router-dom";

import { ButtonLink } from "../../commons/styles";

export const Wrapper = styled.div`
  display: flex;
  margin: 150px 50px 40px 50px;
  background: ${({ theme }) => theme.white};
  border: 1px solid rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    margin: 40px 20px 0 20px;
    flex-direction: column;
  }
`;

export const Visu = styled(UnstyledLink)`
  flex: 1 0 50%;
  line-height: 0;

  img {
    width: 100%;
  }
`;

export const Infos = styled.div`
  display: flex;
  flex: 1 0 50%;
  padding: 0 50px;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

export const Metas = styled.div`
  display: flex;
`;

export const Season = styled.div`
  text-transform: uppercase;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
`;

export const Date = styled.div`
  margin: 0 0 0 20px;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: ${({ theme }) => rgba(theme.blue, 0.5)};
`;

export const Title = styled.div`
  margin: 10px 0 5px 0;
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;

  @media (max-width: 768px) {
    font-size: 18px;
    line-height: 27px;
  }
`;

export const Intro = styled.div`
  font-size: 16px;
  line-height: 24px;
`;

export const Footer = styled.div`
  margin-top: 25px;
`;

export const Link = styled(ButtonLink)`
  display: inline-block;
  color: ${({ theme }) => theme.blue};
  border-color: ${({ theme }) => theme.blue};

  &:hover {
    background: ${({ theme }) => theme.blue};
    color: ${({ theme }) => theme.white};
  }
`;
