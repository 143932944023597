import React from "react";
import { useIntl } from "react-intl";
import { DateTime } from "luxon";

import Saga from "../../../data/saga.js";

import * as S from "./styles";

const COUNT_EPISODES = 6;

const Home = () => {
  const intl = useIntl();

  const lastEpisodes = Saga.filter(s => s.category !== 'postcard').sort((a, b) => {
    const dateA = DateTime.fromISO(a.publishedAt);
    const dateB = DateTime.fromISO(b.publishedAt);
    return dateA < dateB ? -1 : dateA > dateB ? 1 : 0;
  })
    .reverse()
    .slice(0, COUNT_EPISODES);

  return (
    <S.Wrapper>
      <S.Title>{intl.formatMessage({ id: "saga.home.title" })}</S.Title>

      <S.Episodes>
        {lastEpisodes.map((elt, i) => (
          <S.Episode to={`/saga/${elt.slug}`} key={`episode-${i}`}>
            <S.EpisodeHeader>
              <S.EpisodeTitle>{elt.title}</S.EpisodeTitle>
              {elt.subtitle && (
                <S.EpisodeSubTitle dangerouslySetInnerHTML={{ __html: elt.subtitle }} />
              )}
            </S.EpisodeHeader>
            <S.Img src={elt.cover} alt={elt.title} />
          </S.Episode>
        ))}
      </S.Episodes>

      <S.Footer>
        <S.Link to="/saga">
          {intl.formatMessage({ id: "saga.home.button" })}
        </S.Link>
      </S.Footer>
    </S.Wrapper>
  );
};

export default Home;
