import styled from "styled-components";

import { ButtonLink, Rotate } from "../../commons/styles";

export const Wrapper = styled.section`
  display: flex;
  flex: 1;
`;

export const Left = styled.div`
  flex: 1;
  display: flex;
  align-self: center;
  justify-content: flex-end;
  padding-right: 80px;

  div {
    display: flex;
    flex-direction: row;
    max-width: 450px;
  }

  @media (max-width: 1280px) {
    padding: 0 35px;
  }
  @media (max-width: 768px) {
    display: none;
  }
`;

export const Logo = styled.div`
  display: block;
  margin-right: 20px;
  svg {
    width: 100px;
    height: auto;

    transition: width 0.2s ease-in;
  }

  @media (max-width: 768px) {
    svg {
      width: 75px;
    }
  }
`;

export const Intro = styled.p`
  color: ${({ theme }) => theme.blue};
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
  strong {
    font-size: 20px;
    font-weight: normal;
    line-height: 29px;
    font-family: "Permanent Marker";
  }
`;

export const Title = styled(Rotate)`
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;

  @media (max-width: 768px) {
    font-size: 18px;
    line-height: 27px;
  }
`;

export const Right = styled.div`
  flex: 1;
  padding: 32px 50px 50px 50px;
  color: ${({ theme }) => theme.white};
  background: #e30613;

  @media (max-width: 1280px) {
    padding: 35px;
  }
`;

export const Content = styled.p`
  max-width: 500px;
  margin: 25px 0;
  font-size: 14px;
  line-height: 21px;

  strong {
    font-weight: bold;
  }

  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 21px;
  }
`;

export const Footer = styled.div`
  @media (max-width: 768px) {
    display: flex;
    justify-content: center;
  }
`;

export const Link = styled(ButtonLink)`
  display: inline-block;
  color: ${({ theme }) => theme.white};
  border-color: ${({ theme }) => theme.white};

  &:hover {
    background: ${({ theme }) => theme.white};
    color: #e30613;
  }
`;
