import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { Helmet } from "react-helmet";
import Insta from "react-insta-stories";
import { isMobile } from "react-device-detect";

import CloseIcon from "../images/Close";
import * as S from "./styles";
import * as C from "../commons/styles";
import * as P from "../Podcast/Episode/styles";

const Story = ({ go2next, onClose, story }) => {
  const intl = useIntl();

  const keywords = useMemo(
    () =>
      ["object", "designer", "brand", "tags"]
        .reduce((list, key) => {
          const value = story[key];
          if (value === null) return list;
          if (Array.isArray(value)) return [...list, ...value];
          list.push(value);
          return list;
        }, [])
        .join(","),
    [story]
  );

  return (
    <S.Wrapper>
      <Helmet>
        <title>
          Punktional - {story.object} {story.designer}
        </title>
        <meta
          name="title"
          content={`Punktional - ${story.object} ${story.designer}`}
        />
        <meta name="description" content={story.resume} />
        <meta name="keywords" content={keywords} />
        <link
          rel="canonical"
          href={`https://punktional.com/stories/${story.slug}`}
        />
      </Helmet>
      <S.Close onClick={onClose}>
        <CloseIcon color="#010023" />
      </S.Close>

      <C.Content>
        <S.Content>
          <h2>
            {story.object}, {story.designer}
          </h2>
          {!isMobile && (
            <p dangerouslySetInnerHTML={{ __html: story.content }} />
          )}
        </S.Content>

        <S.Stories>
          <S.List>
            <Insta
              key={story.slug}
              stories={story.images}
              defaultInterval={3000}
              loop
              width={isMobile ? 270 : 360}
              height={isMobile ? 480 : 640}
              onAllStoriesEnd={false && go2next}
            />
          </S.List>
        </S.Stories>
      </C.Content>

      {story.podcast && (
        <S.Podcast>
          <h3>{story.podcast.title}</h3>
          {story.podcast.fluxes.map((flux, i) => (
            <P.Player>
              <iframe
                key={i}
                src={`https://open.spotify.com/embed/episode/${flux}`}
                width="100%"
                height="232"
                frameBorder="0"
                allowFullScreen
                allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
              />
            </P.Player>
          ))}

          {Object.entries(story.podcast.links).map(([name, link]) => (
            <P.ButtonListen
              target="_blank"
              href={link}
              kind={name}
              alt={intl.formatMessage({ id: `podcast.listen.${name}` })}
              title={intl.formatMessage({ id: `podcast.listen.${name}` })}
            >
              {intl.formatMessage({ id: `podcast.listen.${name}` })}
            </P.ButtonListen>
          ))}
        </S.Podcast>
      )}
    </S.Wrapper>
  );
};

Story.propTypes = {
  go2next: PropTypes.func.isRequired,
  story: PropTypes.object.isRequired,
};

export default Story;
