import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";

import * as S from "./styles";

const Designer = ({ designer }) => {
  const intl = useIntl();

  return (
    <S.Wrapper>
      <h2>
        {intl.formatMessage(
          { id: `designer.title.${designer.type}` },
          {
            span: (...chunk) => <span>{chunk}</span>,
          }
        )}
      </h2>

      <S.Content>
        <S.Img src={designer.image} alt={designer.name} />
        <p dangerouslySetInnerHTML={{ __html: designer.description }} />
      </S.Content>
    </S.Wrapper>
  );
};

Designer.propTypes = {
  designer: PropTypes.object.isRequired,
};

export default Designer;
