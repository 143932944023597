import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { DateTime } from "luxon";
import Modal from "react-modal";

import Layout from "../../components/Layout";
import StoriesList from "../../components/Stories/List";
import Story from "../../components/Story";

import stories from "../../data/stories.js";

import * as S from "./styles";

const customStyles = {
  content: {
    height: "fit-content",
    border: "none",
    zIndex: 20,
    borderRadius: 5,
  },
};

Modal.setAppElement("#root");

const Stories = () => {
  const intl = useIntl();
  const { slug } = useParams();
  const history = useHistory();
  const query = new URLSearchParams(useLocation().search);

  const [story, setStory] = useState();
  const [sortedList, setSortedList] = useState();

  useEffect(() => {
    setSortedList(
      stories
        .filter((elt) => DateTime.fromISO(elt.publishedAt) < DateTime.now())
        .sort((a, b) => {
          const dateA = DateTime.fromISO(a.publishedAt);
          const dateB = DateTime.fromISO(b.publishedAt);
          return dateA < dateB ? -1 : dateA > dateB ? 1 : 0;
        })
        .reverse()
    );
  }, []);

  useEffect(() => {
    if (!sortedList || !slug) return setStory(null);
    const item = stories.find(
      (elt) => elt.slug.toLowerCase() === slug.toLowerCase()
    );
    if (item) {
      setStory(item);
    } else {
      history.push("/stories/");
      setStory(null);
    }
  }, [slug, sortedList]);

  const handleNextStory = () => {
    const currentIndex = sortedList.findIndex((elt) => elt.slug === story.slug);
    const next = sortedList[(currentIndex + 1) % sortedList.length];
    setStory(next);
    history.push(`/stories/${next.slug}`);
  };

  const handleCloseStory = (e) => {
    if (e) e.preventDefault();
    const search = query.get("search");
    history.push(`/stories/${search ? `?search=${search}` : ""}`);
  };

  return (
    <Layout subFooter={false} newsletter={false}>
      {story ? (
        <Modal
          isOpen
          onRequestClose={handleCloseStory}
          style={customStyles}
          shouldCloseOnOverlayClick
        >
          <Story
            story={story}
            go2next={handleNextStory}
            onClose={handleCloseStory}
          />
        </Modal>
      ) : (
        <S.Wrapper>
          <h1>{intl.formatMessage({ id: "stories.title" })}</h1>
          <p>
            {intl.formatMessage(
              { id: "stories.intro" },
              {
                br: <br />,
                b: (...chunk) => <strong>{chunk}</strong>,
              }
            )}
          </p>

          {false && (
            <S.Link
              href="https://www.instagram.com/punktional_/"
              target="blank"
              rel="nofollow"
            >
              {intl.formatMessage({ id: "home.intro.link" })}
            </S.Link>
          )}

          {sortedList && <StoriesList stories={sortedList} />}
        </S.Wrapper>
      )}
    </Layout>
  );
};

export default Stories;
