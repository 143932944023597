import styled from "styled-components";

import { ButtonLink, Rotate } from "../../commons/styles";

export const Wrapper = styled.div`
  display: inline-block;
  padding: 34px 75px 75px 75px;
  max-width: 600px;

  img {
    margin: 0 0 40px 68px;
  }
  p {
    margin: 25px 0 0 0;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: ${({ theme }) => theme.white};
  }

  @media (max-width: 768px) {
    flex: 1;
    padding: 30px 35px;

    img {
      display: block;
      margin: 0 auto 30px auto;
      width: 200px;
    }
    p {
      margin: 15px 0 0 0;
      font-size: 12px;
      line-height: 18px;
    }
  }

  @media (max-width: 1280px) {
    padding: 34px 35px 75px 35px;

    img {
      display: block;
      margin: 0 auto 40px auto;
    }
  }
`;

export const Title = styled(Rotate)`
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  color: ${({ theme }) => theme.white};

  @media (max-width: 768px) {
    font-size: 18px;
    line-height: 27px;
  }
`;

export const Footer = styled.div`
  @media (max-width: 768px) {
    display: flex;
    justify-content: center;
  }
`;

export const Link = styled(ButtonLink)`
  display: inline-block;
  margin-top: 25px;
  color: ${({ theme }) => theme.white};
  border-color: ${({ theme }) => theme.white};

  &:hover {
    background: ${({ theme }) => theme.white};
    color: ${({ theme }) => theme.blue};
  }
`;

export const ComingSoon = styled(ButtonLink)`
  display: inline-block;
  margin-top: 25px;
  color: ${({ theme }) => theme.white};
  border-color: ${({ theme }) => theme.white};
  cursor: default;

  &:hover {
    color: ${({ theme }) => theme.white};
    border-color: ${({ theme }) => theme.white};
  }
`;
