import styled from "styled-components";

import * as C from "../../commons/styles";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 40px 0 90px 0;

  @media (max-width: 768px) {
    flex-direction: column;
    padding-top: 0;
  }
`;

export const Flocking = styled.div`
  position: absolute;
  bottom: 15px;
  right: 15px;
  width: 60px;
  height: 60px;
  svg {
    width: 100%;
    height: 100%;
  }
`;

export const SliderWrapper = styled.div`
  width: 50%;
  .slick-slider {
    margin: 0;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const Details = styled.div`
  flex: 1;
  margin-left: 100px;

  h1 {
    margin: 0 0 16px 0;
    font-weight: bold;
    font-size: 48px;
    line-height: 72px;
  }

  h2 {
    margin: 0 0 16px 0;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
  }

  p {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    margin: 40px 0 0 0;
    h1 {
      margin: 0 0 6px 0;
      font-size: 24px;
      line-height: 36px;
    }

    h2,
    p {
      font-size: 12px;
      line-height: 18px;
    }
  }
`;

export const Price = styled.div`
  margin-top: 30px;
  font-weight: 500;
  font-size: 20px;
  line-height: 36px;
`;

export const BuyBtn = styled(C.Button)`
  display: inline-block;
  margin: 50px 0 50px 0;
  align-self: flex-start;

  @media (max-width: 768px) {
    width: auto;
    margin: 16px auto 50px auto;
    padding: 8px 21px;
    transform: none;
    text-align: center;

    font-size: 12px;
    line-height: 18px;
  }
`;

export const Image = styled.div`
  position: relative;
  max-width: 560px;

  img {
    width: 100%;
    height: auto;
    border: none;
  }
`;

export const Tabs = styled.ul`
  display: flex;
`;

export const Tab = styled.li`
  flex: 1;
  font-weight: ${(props) => (props.active ? "bold" : "normal")};
  font-size: 16px;
  line-height: 20px;
  cursor: pointer;

  @media (max-width: 768px) {
    font-size: 12px;
    line-height: 18px;
  }
`;

export const TabPanel = styled.ul`
  margin-top: 15px;
  min-height: 150px;

  li {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    & + li {
      margin-top: 5px;
    }
  }

  @media (max-width: 768px) {
    min-height: 100px;
    li {
      font-size: 12px;
      line-height: 18px;
    }
  }
`;
