import styled from "styled-components";
import { rgba } from "polished";
import { Link } from "react-router-dom";

export const Hover = styled.div`
  display: none;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => rgba(theme.blue, 0.39)};

  svg {
    width: 20%;
  }
`;

export const Wrapper = styled(Link)`
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: ${({ theme }) => theme.white};
  color: ${({ theme }) => theme.blue};
  text-decoration: none;

  &:hover {
    ${Hover} {
      display: flex;
    }
  }

  @media (max-width: 768px) {
    margin-bottom: 30px;
  }
`;

export const Preview = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  overflow: hidden;
￼ align-items: center;
  cursor: pointer;
  flex-direction: column;

  img {
    width: 100%;
    max-height: 358px;
    object-fit: cover;
  }
`;

export const Infos = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 30px;
`;

export const Metas = styled.div`
  display: flex;
`;

export const Season = styled.div`
  text-transform: uppercase;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
`;

export const Title = styled.div`
  margin: 5px 0 0 0;
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
`;
