import React from "react";
import PropTypes from "prop-types";

import Header from "../Header";
import SubFooter from "../SubFooter";
import Newsletter from "../Newsletter";
import Footer from "../Footer";

import * as S from "./styles";

const Layout = ({
  className,
  children,
  newsletter = true,
  subFooter = true,
}) => (
  <S.Wrapper className={className}>
    <Header />
    <S.Main>
      {children}
      {subFooter && <SubFooter />}
      {newsletter && (
        <S.Content>
          <Newsletter />
        </S.Content>
      )}
    </S.Main>
    <Footer />
  </S.Wrapper>
);

Layout.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  newsletter: PropTypes.bool,
  subFooter: PropTypes.bool,
};

export default Layout;
