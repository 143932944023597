import styled from "styled-components";
import { Link } from "react-router-dom";
import { rgba } from "polished";

export const Wrapper = styled.section`
  margin-top: 30px;
  padding: 80px 110px;
  background: ${({ theme }) => theme.white};
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 2px;
  color: ${({ theme }) => theme.blue};
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  @media (max-width: 768px) {
    margin: 20px;
    padding: 20px;
  }
`;

export const GoBack = styled(Link)`
  margin: 20px 0 0 20px;
  text-decoration: none;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  color: ${({ theme }) => theme.blue};
  transition: color 0.2s;

  &:hover {
    color: ${({ theme }) => rgba(theme.blue, 0.39)};
  }

  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const Cover = styled.img`
  display: flex;
  margin: 0 auto 50px auto;
  max-width: 500px;
  height: auto;

  @media (max-width: 768px) {
    width: 100%;
    max-width: 100%;
  }
`;

export const Title = styled.h1`
  margin-bottom: 0;
  padding: 0 100px;
  font-weight: bold;
  font-size: 34px !important;
  line-height: 1.4 !important;

  span {
    display: block;
  }

  @media (max-width: 768px) {
    padding: 0;
    font-size: 16px !important;
    line-height: 24px !important;
  }
`;

export const Intro = styled.p`
  padding: 0 100px;
  font-style: italic;

  @media (max-width: 768px) {
    padding: 0;
  }
`;

export const Article = styled.div`
  padding: 0 100px;
  strong,
  b {
    font-weight: bold;
  }

  dl {
    margin-top: 18px;
    font-size: 14px;
    line-height: 21px;
    dt {
      font-weight: bold;
    }
    dd {
      display: list-item;
      list-style: disc;
      margin-left: 13px;
      font-weight: 500;
    }
  }

  p + p {
    margin-top: 20px;
  }
  h2 {
    margin: 60px 0 20px 0;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
  }

  a {
    color: inherit;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }

  @media (max-width: 768px) {
    padding: 0;

    h2 {
      font-size: 12px;
      line-height: 18px;
    }
  }
`;

export const Focus = styled.div`
  margin: 100px 0 0 0;
`;

export const FTitle = styled.h2`
  margin-bottom: 20px;
  padding: 0 100px;
  font-weight: bold;
  font-size: 24px !important;
  line-height: 1.4 !important;

  @media (max-width: 768px) {
    padding: 0;
    font-size: 16px !important;
    line-height: 24px !important;
  }
`;

export const FIntro = styled.p`
  padding: 0 100px;
  font-style: italic;

  @media (max-width: 768px) {
    padding: 0;
  }
`;

export const FElt = styled.div`
  display: flex;
  margin: 30px 0 0 0;

  img {
    width: 35%;
    min-width: 300px;
    margin-right: 20px;
  }

  &:nth-child(even) {
    flex-direction: row-reverse;
    img {
      margin: 0 0 0 20px;
    }
  }

  @media (max-width: 768px) {
    &,
    &:nth-child(even) {
      flex-direction: column;
      img {
        width: 100%;
        margin: 0 0 20px 0;
      }
    }
  }
`;

export const Objects = styled.section`
  margin-top: 50px;
  @media (max-width: 768px) {
    padding: 0 20px;
  }
`;

export const SubTitle = styled.h2`
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;

  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const Stories = styled.section`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 40px;
  margin-top: 40px;

  @media (max-width: 1280px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;
