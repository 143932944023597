import styled from "styled-components";

import * as C from "../../components/commons/styles";

export const Wrapper = styled(C.Content)`
  position: relative;
  margin: 0 auto 300px auto;
  padding: 5px 0;
  flex-direction: column;

  @media (max-width: 1280px) {
    padding: 5px 35px;
  }
  @media (max-width: 768px) {
    margin: -10px 0 0 0;
    padding: 30px 0 20px 0;
    overflow-x: hidden;
  }

  &::before {
    z-index: -1;
    content: "";
    position: absolute;
    top: -30vh;
    left: 50%;
    width: 120vw;
    height: 50vh;
    background: ${({ theme }) => theme.blue};
    transform: translateX(-50%) rotate(-3deg);

    @media (max-width: 768px) {
      height: 55vh;
    }
  }
`;

export const Title = styled.h1`
  margin: 0 20px;
  color: ${({ theme }) => theme.white};
  text-align: center;

  @media (max-width: 768px) {
    font-size: 22px !important;
    line-height: 32px !important;
  }
`;

export const Intro = styled.p`
  width: 550px;
  margin: 0 auto;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: ${({ theme }) => theme.white};

  @media (max-width: 768px) {
    width: auto;
    max-width: 550px;
    padding: 0 20px;
  }
`;
