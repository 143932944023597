import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { useHistory, useLocation } from "react-router-dom";

import DeleteIcon from "../../images/Delete";
import SearchIcon from "../../images/Search";
import Item from "../Item";

import * as S from "./styles";

const List = ({ stories }) => {
  const intl = useIntl();
  const history = useHistory();
  const query = new URLSearchParams(useLocation().search);

  const [results, setResults] = useState(stories);
  const [search, setSearch] = useState("");

  useEffect(() => {
    const search = query.get("search");
    if (search) setSearch(search);
  }, []);

  useEffect(() => {
    let list = stories;
    if (search !== "") {
      const words = search.toLowerCase().trim().split(" ");
      list = stories.filter((elt) => {
        const { brand = "", designer = "", object = "" } = elt;
        return (
          words.some(
            (word) =>
              brand && brand.toLowerCase().includes(word.toLowerCase())
          ) ||
          words.some(
            (word) =>
              object && object.toLowerCase().includes(word.toLowerCase())
          ) ||
          words.some(
            (word) =>
              designer && designer.toLowerCase().includes(word.toLowerCase())
          )
        );
      });
      history.replace(`?search=${search}`);
    } else {
      history.replace();
    }
    setResults(list);
  }, [search]);

  const handleChange = (e) => {
    e.preventDefault();
    setSearch(e.target.value);
  };

  const handleClearSearch = (e) => {
    e.preventDefault();
    setSearch("");
  };

  return (
    <S.Wrapper>
      <S.Header>
        <SearchIcon color="rgba(16, 0, 35, 0.6)" />
        <S.Search
          type="text"
          value={search}
          onChange={handleChange}
          placeholder={intl.formatMessage({ id: "input.search.placeholder" })}
        />
        {search !== "" && (
          <S.DeleteBtn onClick={handleClearSearch}>
            <DeleteIcon color="rgba(16, 0, 35,0.6)" />
          </S.DeleteBtn>
        )}
      </S.Header>
      <S.List>
        {results.length > 0 ? (
          <>
            {results.map((elt, i) => (
              <Item key={`story-${elt.slug}`} story={elt} />
            ))}
          </>
        ) : (
          <S.NoResults>{intl.formatMessage({ id: "no_results" })}</S.NoResults>
        )}
      </S.List>
    </S.Wrapper>
  );
};

List.propTypes = {
  stories: PropTypes.array.isRequired,
};

export default List;
