import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { DateTime } from "luxon";

import stories from "../../../data/stories";
import sagas from "../../../data/saga.js";

import Story from "../../Stories/Item";
import Saga from "../../Saga/Item";

import * as S from "./styles";

const Episode = ({ episode }) => {
  const intl = useIntl();

  return (
    <>
      <S.GoBack to="/podcast">
        {intl.formatMessage({ id: "common.back" })}
      </S.GoBack>

      <S.Wrapper>
        <S.Header>
          <S.Visu>
            <img src={episode.images.object} alt={episode.object} />
          </S.Visu>
          <S.Visu>
            <img src={episode.images.designer} alt={episode.designer} />
          </S.Visu>
        </S.Header>

        <S.Content>
          <S.Metas>
            <S.Season>
              {intl.formatMessage(
                { id: "podcast.item.season" },
                {
                  name: episode.season,
                  num: episode.episodeNumber,
                }
              )}
            </S.Season>
            <S.Date>
              {DateTime.fromISO(episode.publishedAt).toLocaleString(
                DateTime.DATE_SHORT
              )}
            </S.Date>
          </S.Metas>
          <S.Title>{episode.title}</S.Title>
          <S.Intro dangerouslySetInnerHTML={{ __html: episode.intro }} />

          <S.Player>
            <iframe
              src={`https://open.spotify.com/embed/episode/${episode.flux}`}
              width="100%"
              height="232"
              frameBorder="0"
              allowFullScreen
              allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
            />
          </S.Player>

          {Object.entries(episode.links).map(([name, link]) => (
            <S.ButtonListen
              target="_blank"
              href={link}
              kind={name}
              alt={intl.formatMessage({ id: `podcast.listen.${name}` })}
              title={intl.formatMessage({ id: `podcast.listen.${name}` })}
            >
              {intl.formatMessage({ id: `podcast.listen.${name}` })}
            </S.ButtonListen>
          ))}

          <S.Description
            dangerouslySetInnerHTML={{ __html: episode.content }}
          />
        </S.Content>
      </S.Wrapper>

      {episode.stories.length > 0 && (
        <S.Objects>
          <S.SubTitle>
            {intl.formatMessage({ id: "podcast.stories.title" })}
          </S.SubTitle>
          <S.Items>
            {stories
              .filter((elt) => episode.stories.includes(elt.slug))
              .map((story) => (
                <Story key={`story-${story.slug}`} story={story} />
              ))}
          </S.Items>
        </S.Objects>
      )}

      {episode.sagas.length > 0 && (
        <S.Objects>
          <S.SubTitle>
            {intl.formatMessage({ id: "podcast.saga.title" })}
          </S.SubTitle>
          <S.Items>
            {sagas
              .filter((elt) => episode.sagas.includes(elt.slug))
              .map((saga) => (
                <Saga key={`saga-${saga.slug}`} article={saga} />
              ))}
          </S.Items>
        </S.Objects>
      )}
    </>
  );
};

Episode.propTypes = {
  episode: PropTypes.object.isRequired,
};

export default Episode;
