import styled from "styled-components";

import { ButtonLink, Rotate } from "../commons/styles";
import FormDefault from "./Form";

import Bialetti from "../images/Bialetti/bialetti.svg";

export const Wrapper = styled.div`
  max-width: 1200px;
  flex: 1;
  background: ${({ theme }) => theme.kingBlue || "#002fa7"};
  color: ${({ theme }) => theme.white};

  @media (max-width: 768px) {
    padding: 30px 35px;
  }

  @media (max-width: 1280px) {
    padding: 35px;
  }
`;

export const Background = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  bottom: 0;
  right: 0;
  background: url("${Bialetti}") right center / 50vw no-repeat;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const Content = styled.section`
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 35px 0 45px 0;

  @media (max-width: 768px) {
    padding: 0;
    width: 100%;
  }
`;

export const Header = styled.header`
  margin-bottom: 20px;
`;

export const Title = styled(Rotate)`
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;

  @media (max-width: 768px) {
    font-size: 18px;
    line-height: 27px;
  }
`;

export const Intro = styled.p`
  margin-top: 60px;
  font-size: 16px;
  line-height: 24px;
  font-weight: normal;

  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 21px;
  }
`;

export const Link = styled(ButtonLink)`
  display: inline-block;
  margin-top: 25px;
  color: ${({ theme }) => theme.white};
  border-color: ${({ theme }) => theme.white};

  &:hover {
    background: ${({ theme }) => theme.white};
    color: ${({ theme }) => theme.blue};
  }

  @media (max-width: 768px) {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
  }
`;

export const Close = styled.span`
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 10px;

  svg {
    width: 40px;
  }

  @media (max-width: 768px) {
    svg {
      width: 25px;
    }
  }
`;

export const Img = styled.div`
  @media (max-width: 768px) {
    img {
      width: 100%;
    }
  }
`;

export const Form = styled(FormDefault)`
  @media (max-width: 768px) {
    padding: 0 10px;
  }
`;
