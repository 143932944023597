import styled from "styled-components";

import * as C from "../../components/commons/styles";

export const Wrapper = styled(C.Content)`
  margin: 100px auto 200px auto;

  img {
    margin-left: auto;
    max-width: 550px;
    height: 100%;
  }

  @media (max-width: 1280px) {
    padding: 0 35px;
  }
  @media (max-width: 768px) {
    margin: 20px 0;
    padding: 0;
    flex-direction: column-reverse;

    img {
      width: 100%;
    }
  }
`;

export const Content = styled.section`
  width: 50%;
  padding-right: 50px;
  font-size: 14px;
  line-height: 21px;

  a {
    font-weight: bold;
    color: inherit;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

  @media (max-width: 768px) {
    width: 100%;
    padding: 50px 20px 100px 20px;
  }
`;
