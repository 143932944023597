import styled from "styled-components";

import { ButtonLink, Rotate } from "../../commons/styles";

export const Wrapper = styled.div`
  display: inline-block;
  max-width: 412px;
  padding: 0 0 0 64px;
  box-sizing: content-box;

  p {
    margin: 25px 0 0 0;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: ${({ theme }) => theme.blue};
  }

  @media (max-width: 768px) {
    padding: 30px 35px;

    p {
      font-size: 12px;
      line-height: 18px;
    }
  }
`;

export const Images = styled.div`
  display: grid;
  grid-template-columns: 212px 200px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
`;

export const Img = styled.img`
  margin: 0 12px 12px 0;
  width: 200px;
  height: 200px;

  @media (max-width: 768px) {
    margin: 0 4px 8px 0;
    width: calc(100% - 4px);
    height: auto;
  }
  &:nth-child(2n) {
    margin: 0 0 8px 4px;
  }
`;

export const Title = styled(Rotate)`
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  color: ${({ theme }) => theme.blue};

  @media (max-width: 768px) {
    margin-bottom: 15px;
  }
`;

export const Footer = styled.div`
  @media (max-width: 768px) {
    display: flex;
    justify-content: center;
  }
`;

export const Link = styled(ButtonLink)`
  display: inline-block;
  margin-top: 25px;
  color: ${({ theme }) => theme.blue};
  border-color: ${({ theme }) => theme.blue};

  &:hover {
    background: ${({ theme }) => theme.blue};
    color: ${({ theme }) => theme.white};
  }
`;
