import styled from "styled-components";
import { rgba } from "polished";
import { Link } from "react-router-dom";

export const Flocking = styled.div`
  position: absolute;
  bottom: 15px;
  right: 15px;
  width: 60px;
  height: 60px;
  svg {
    width: 100%;
    height: 100%;
  }
`;

export const Preview = styled.div`
  position: relative;
  padding-top: 100%;
  margin-bottom: 28px;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  @media (max-width: 768px) {
    margin-bottom: 14px;
  }
`;

export const Title = styled.h2`
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

export const Hover = styled.div`
  display: none;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => rgba(theme.blue, 0.39)};

  svg {
    width: 20%;
  }
`;

export const Wrapper = styled(Link)`
  flex: 0 47%;
  text-align: left;
  cursor: pointer;
  text-decoration: none;
  color: inherit;
  margin-bottom: 70px;

  &:hover {
    ${Hover} {
      display: flex;
    }
  }

  @media (max-width: 768px) {
    flex: 100%;
    margin-bottom: 50px;
  }
`;
