import React, { useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { NavLink, useHistory, useParams } from "react-router-dom";
import { useIntl } from "react-intl";
import { createGlobalStyle } from "styled-components";
import { DateTime } from "luxon";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import { isMobile } from "react-device-detect";

import SummerIcon from "../../components/images/Summer";

import sagas from "../../data/saga";

import Layout from "../../components/Layout";
import Parasol from "../../components/images/Parasol/parasol_colors.svg";
import Article from "../../components/Saga/Article";
import ArticleList from "../../components/Saga/List";

import * as S from "./styles";

const GlobalStyle = createGlobalStyle`
  body {
    background: url('${Parasol}') 40vw -300px no-repeat;

    @media (max-width: 768px) {
      background-position: 21vw -165px;
      background-size: 130%;

      #root main header {
        background: transparent;

        &.sticky {
          background: #fff;
        }
      }
    }
  }
`;

const CATEGORIES = ["visite", "rencontre", "portrait", "postcard", "confort", "duo"];

const Saga = () => {
  const intl = useIntl();
  const { slug } = useParams();
  const history = useHistory();

  const [saga, setSaga] = useState();

  const sortedList = useMemo(
    () =>
      CATEGORIES.reduce((acc, cat) => {
        acc[cat] = sagas
          .filter((elt) => elt.category === cat)
          .sort((a, b) => {
            const dateA = DateTime.fromISO(a.publishedAt);
            const dateB = DateTime.fromISO(b.publishedAt);
            return dateA < dateB ? -1 : dateA > dateB ? 1 : 0;
          })
          .reverse();
        return acc;
      }, []),
    [sagas]
  );
  useEffect(() => {
    if (!slug) {
      setSaga(null);
      return;
    }

    const item = sagas.find(
      (elt) => elt.slug.toLowerCase() === slug.toLowerCase()
    );

    if (item) {
      setSaga(item);
    } else {
      history.push("/saga");
      setSaga(null);
    }
  }, [slug]);

  const getTitle = () =>
    `Punktional - ${
      saga
        ? `${saga.title} ${saga.subtitle}`
        : intl.formatMessage({ id: "saga.title" }, { br: " " })
    }`;

  return (
    <Layout subFooter={false} newsletter={false}>
      <GlobalStyle />
      <Helmet>
        <title>{getTitle()}</title>
      </Helmet>
      <S.Wrapper>
        <S.Title>
          {intl.formatMessage({ id: "saga.title" }, {
            link: (...chunk) => <a href="/newsletter">{chunk}</a>,
            br: <br />
          })}
        </S.Title>

        {saga ? (
          <Article saga={saga} />
        ) : (
          <>
            {CATEGORIES.map((cat) => (
              <>
                {sortedList[cat].length > 0 && (
                  <S.Category>
                    <S.SubTitle id={`saga-${cat}`}>
                      {intl.formatMessage(
                        { id: `saga.category.${cat}.title` },
                        { br: <br /> }
                      )}
                    </S.SubTitle>

                    <S.Intro>
                      {intl.formatMessage(
                        { id: `saga.category.${cat}.intro` },
                        { br: <br /> }
                      )}
                    </S.Intro>

                    <ArticleList sagas={sortedList[cat]} />
                  </S.Category>
                )}
              </>
            ))}
          </>
        )}
      </S.Wrapper>
    </Layout>
  );
};

export default Saga;
