import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";

import EyeIcon from "../../images/Eye";

import * as S from "./styles";

const Item = ({ episode }) => {
  const intl = useIntl();

  return (
    <S.Wrapper to={`/podcast/${episode.slug}`}>
      <S.Preview>
        <S.Hover>
          <EyeIcon />
        </S.Hover>

        <img
          src={episode.images.cover}
          alt={`${episode.season} - ${episode.episodeNumber}${episode.title}`}
        />
      </S.Preview>

      <S.Infos>
        <S.Metas>
          <S.Season>
            {intl.formatMessage(
              { id: "podcast.item.season" },
              {
                name: episode.season,
                num: episode.episodeNumber,
              }
            )}
          </S.Season>
        </S.Metas>
        <S.Title>{episode.title}</S.Title>
      </S.Infos>
    </S.Wrapper>
  );
};

Item.propTypes = {
  episode: PropTypes.object.isRequired,
  focus: PropTypes.bool,
};

export default Item;
