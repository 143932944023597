import React from "react";
import { Helmet } from "react-helmet";
import { useIntl } from "react-intl";

import Layout from "../../components/Layout";

import * as S from "./styles";

const About = () => {
  const intl = useIntl();

  return (
    <Layout subFooter={false}>
      <Helmet>
        <title>Punktional - {intl.formatMessage({ id: "about.title" })}</title>
      </Helmet>
      <S.Wrapper>
        <S.Content>
          {intl.formatMessage(
            { id: "about.content" },

            {
              br: <br />,
              nowrap: (...chunk) => (
                <span style={{ whiteSpace: "nowrap" }}>{chunk}</span>
              ),
              b: (...chunk) => <strong>{chunk}</strong>,
              contact: (...chunk) => (
                <a href="mailto:hello@punktional.com">{chunk}</a>
              ),
            }
          )}
        </S.Content>
        <img src="/images/about/about-us.jpeg" alt="About us" />
      </S.Wrapper>
    </Layout>
  );
};

export default About;
