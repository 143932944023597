import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import Slider from "react-slick";

import MakersIcon from "../../images/Maker";

import * as S from "./styles";

const Details = ({ product }) => {
  const intl = useIntl();

  const [isOpen, setIsOpen] = useState(false);
  const [currentTab, setCurrentTab] = useState("properties");
  const [currentSku, setCurrentSku] = useState();

  useEffect(() => {
    if (product.skus) {
      setCurrentSku(product.skus[0].id);
    } else {
      setCurrentSku(product.id);
    }
  }, []);

  const settings = {
    arrows: false,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <S.Wrapper>
      <S.SliderWrapper>
        <Slider {...settings}>
          {product.images.map((elt, i) => (
            <S.Image key={`img-${i}`}>
              <img src={elt} alt={product.title} />
              <S.Flocking>
                <MakersIcon />
              </S.Flocking>
            </S.Image>
          ))}
        </Slider>
      </S.SliderWrapper>

      <S.Details>
        <h1>{product.title}</h1>
        <h2>{product.designer.name}</h2>
        <p dangerouslySetInnerHTML={{ __html: product.description }} />

        <S.Price>
          {product.price}
          {!isNaN(product.price) && " €"}
        </S.Price>

        <S.BuyBtn href="mailto:hello@punktional.com">Contactez-nous</S.BuyBtn>

        <S.Tabs>
          {["properties" /* "delivery" */].map((elt) => (
            <S.Tab
              key={`tab-${elt}`}
              active={elt === currentTab}
              onClick={() => setCurrentTab(elt)}
            >
              {intl.formatMessage({ id: `details.tabs.${elt}` })}
            </S.Tab>
          ))}
        </S.Tabs>
        <S.TabPanel>
          {Object.values(product[currentTab]).map(
            (line, i) =>
              line && (
                <li
                  key={`${currentTab}-${i}`}
                  dangerouslySetInnerHTML={{ __html: `- ${line}` }}
                />
              )
          )}
        </S.TabPanel>
      </S.Details>
    </S.Wrapper>
  );
};

Details.propTypes = {
  product: PropTypes.object.isRequired,
};

export default Details;
