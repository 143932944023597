import React, { useContext } from "react";
import PropTypes from "prop-types";
import { ThemeContext } from "styled-components";

const Instagram = ({ color }) => {
  const theme = useContext(ThemeContext);
  const fillColor = color ? color : theme.blue;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
      <path
        fill={fillColor}
        d="M36.2,0H13.8C6.2,0,0,6.2,0,13.8v22.4C0,43.8,6.2,50,13.8,50h22.4C43.8,50,50,43.8,50,36.2V13.8C50,6.2,43.8,0,36.2,0z M45.6,36.2c0,5.2-4.2,9.4-9.4,9.4H13.8c-5.2,0-9.4-4.2-9.4-9.4V13.8c0-5.2,4.2-9.4,9.4-9.4h22.4c5.2,0,9.4,4.2,9.4,9.4L45.6,36.2 L45.6,36.2z"
      />
      <path
        fill={fillColor}
        d="M25,12.1c-7.1,0-12.9,5.8-12.9,12.9c0,7.1,5.8,12.9,12.9,12.9S37.9,32.1,37.9,25C37.9,17.9,32.1,12.1,25,12.1z M25,33.4 c-4.7,0-8.4-3.8-8.4-8.4c0-4.7,3.8-8.4,8.4-8.4s8.4,3.8,8.4,8.4C33.4,29.7,29.7,33.4,25,33.4z"
      />
      <path
        fill={fillColor}
        d="M38.4,8.4c-0.9,0-1.7,0.3-2.3,1c-0.6,0.6-1,1.4-1,2.3c0,0.9,0.3,1.7,1,2.3c0.6,0.6,1.4,1,2.3,1c0.9,0,1.7-0.3,2.3-1 c0.6-0.6,1-1.4,1-2.3c0-0.9-0.3-1.7-1-2.3C40.1,8.7,39.3,8.4,38.4,8.4z"
      />
    </svg>
  );
};

Instagram.propTypes = {
  color: PropTypes.string,
};

export default Instagram;
