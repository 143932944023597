import styled from "styled-components";
import { rgba } from "polished";

import * as C from "../../components/commons/styles";

export const Wrapper = styled(C.Content)`
  position: relative;
  margin: 0 auto 150px auto;
  padding: 5px 0;
  flex-direction: column;

  @media (max-width: 1280px) {
    padding: 5px 35px;
  }
  @media (max-width: 768px) {
    margin: 20px 0;
    padding: 0 20px;
  }

  &:first-of-type::before {
    z-index: -1;
    content: "";
    position: absolute;
    top: -25vh;
    left: 50%;
    width: 120vw;
    height: 55vh;
    background: ${({ theme }) => theme.blue};
    transform: translateX(-50%) rotate(-3deg);

    @media (max-width: 768px) {
      height: 50vh;
    }
  }
`;

export const Title = styled.h1`
  margin: 0;
  color: ${({ theme }) => theme.white};
  text-align: center;

  @media (max-width: 768px) {
    font-size: 22px !important;
    line-height: 32px !important;
  }
`;

export const Intro = styled.p`
  width: 550px;
  margin: 0 auto;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: ${({ theme }) => theme.white};

  @media (max-width: 768px) {
    max-width: 550px;
    width: auto;
  }
`;

export const Content = styled.p`
  max-width: 860px;
  margin: 0 auto;

  p {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: ${({ theme }) => theme.blue};
  }
  p + p {
    margin-top: 24px;
  }

  @media (max-width: 768px) {
    p {
      font-size: 14px;
      line-height: 21px;
    }
  }
`;

export const Objects = styled.section`
  margin-top: 150px;
  @media (max-width: 768px) {
    margin-top: 50px;
    padding: 0 20px;
  }
`;

export const SubTitle = styled.h2`
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;

  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const Stories = styled.section`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 40px;
  margin-top: 40px;

  @media (max-width: 1280px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const Insta = styled.div`
  margin: 50px 0;
  text-align: center;

  display: grid;
  grid-template-columns: repeat(3, 1fr);

  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);

    > div {
      margin: 0 auto;
    }
  }
`;

export const Chapters = styled.div`
  display: flex;
  padding: 0 0 0 50px;
  flex-direction: column;
  justify-content: center;
  text-align: left;
`;

export const Chapter = styled.div`
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;

  span {
    display: inline-block;
    font-weight: normal;
  }

  &:hover {
    text-decoration: underline;
  }
`;

export const PdfWrapper = styled.div`
  padding: 28px 0;
  background: rgba(1, 0, 35, 0.05);
`;

export const PdfContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  font-size: 16px;
  line-height: 24px;
`;

export const PdfTitle = styled.p`
  font-weight: bold;
`;

export const Form = styled.form`
  display: flex;
  margin-top: 40px;

  > div {
    display: flex;
  }

  @media (max-width: 768px) {
    height: auto;
    margin: 20px 0;
  }

  svg {
    height: 50px;
    width: auto;
  }
`;

export const Input = styled.input`
  flex: 1;
  padding: 0 15px;
  border: none;
  min-width: 400px;
  border: 1px solid ${({ theme }) => theme.white};
  font-size: 16px;
  font-weight: 300;
  line-height: 30px;
  font-style: italic;
  color: ${({ theme }) => theme.blue};
  outline: none;
  background: ${({ theme }) => rgba(theme.white, 0.6)};

  &::placeholder {
    color: ${({ theme }) => theme.blue};
  }

  @media (max-width: 768px) {
    min-width: 0;
    padding: 7px 0 7px 10px;
    font-size: 12px;
    line-height: 18px;
  }
`;

export const Submit = styled.button`
  margin: 0 0 0 -1px;
  padding: 10px 48px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.blue};
  text-transform: capitalize;
  background: ${({ theme }) => theme.white};
  border: 1px solid ${({ theme }) => theme.white};
  cursor: pointer;

  &:disabled {
    color: ${({ theme }) => rgba(theme.blue, 0.6)};
    cursor: default;
    &:hover {
      color: ${({ theme }) => rgba(theme.blue, 0.6)};
      background: ${({ theme }) => theme.white};
    }
  }

  &:hover {
    background: ${({ theme }) => theme.blue};
    color: ${({ theme }) => theme.white};
  }

  @media (max-width: 768px) {
    margin: 0;
    padding: 6px 25px;
    font-size: 12px;
    line-height: 18px;
  }
`;
