import React, { useContext, useState } from "react";
import { Helmet } from "react-helmet";
import { useIntl } from "react-intl";
import { createGlobalStyle } from "styled-components";
import MailchimpSubscribe from "react-mailchimp-subscribe";

import AppContext from "../../AppContext";

import Layout from "../../components/Layout";
import Bialetti from "../../components/images/Bialetti/bialetti.svg";

import * as S from "./styles";

const GlobalStyle = createGlobalStyle`
  body {
    background: #E30613 url('${Bialetti}') right -30vh / 50vw no-repeat;

  .bm-burger-button {
    span.bm-burger-bars {
      background: #FFF !important;
    }
  }

    @media (max-width: 768px) {
      background-size: 280px;
      background-position: right -80px top -80px;
    }

    #root main header {
      background: transparent;
      ul li a {
        color: #FFF;
      }
      div a svg path {
        fill: #FFF;
      }
    }
  }
`;

const Supersalone = () => {
  const intl = useIntl();
  const config = useContext(AppContext);

  const [email, setEmail] = useState("");

  const handleSubmit = (e, subscribe) => {
    e.preventDefault();
    if (email && email.indexOf("@") > -1) {
      subscribe({ EMAIL: email, TYPE: "supersalone" });
    }
  };

  // if (!config.MAILCHIMP_URL) return null;

  return (
    <Layout subFooter={false} newsletter={false}>
      <GlobalStyle />
      <Helmet>
        <title>
          Punktional - {intl.formatMessage({ id: "supersalone.title" })}
        </title>
      </Helmet>
      <S.Wrapper>
        <S.Content>
          <S.Header>
            <S.Title>
              {intl.formatMessage(
                { id: "supersalone.title" },
                {
                  br: <br />,
                  strong: (txt) => <strong>{txt}</strong>,
                  i: (txt) => <i>{txt}</i>,
                }
              )}
            </S.Title>
            <S.Intro>
              {intl.formatMessage(
                { id: "supersalone.intro" },
                {
                  br: <br />,
                  strong: (txt) => <strong>{txt}</strong>,
                  i: (txt) => <i>{txt}</i>,
                }
              )}
            </S.Intro>
          </S.Header>

          <MailchimpSubscribe
            url={config.MAILCHIMP_URL}
            render={({ subscribe, status, message }) => (
              <>
                {status === "sending" && (
                  <div style={{ color: "blue" }}>
                    {intl.formatMessage({ id: "newsletter.status.sending" })}
                  </div>
                )}
                {status === "error" && (
                  <div
                    style={{ color: "red" }}
                    dangerouslySetInnerHTML={{ __html: message }}
                  />
                )}
                {status === "success" && (
                  <div style={{ color: "green" }}>
                    {intl.formatMessage({ id: "newsletter.status.success" })}
                  </div>
                )}
                {!status && (
                  <S.Form>
                    <div>
                      <S.Input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder={intl.formatMessage({
                          id: "inputs.email.placeholder",
                        })}
                      />
                    </div>
                    <S.Submit
                      type="submit"
                      onClick={(e) => handleSubmit(e, subscribe)}
                    >
                      {intl.formatMessage({ id: "newsletter.submit" })}
                    </S.Submit>
                  </S.Form>
                )}
              </>
            )}
          />
        </S.Content>
      </S.Wrapper>
    </Layout>
  );
};

export default Supersalone;
