import React, { useContext, useState } from "react";
import { Helmet } from "react-helmet";
import { useIntl } from "react-intl";
import { createGlobalStyle } from "styled-components";
import Insta from "react-insta-stories";
import { isMobile } from "react-device-detect";

import AppContext from "../../AppContext";

import stories from "../../data/stories";
import study from "../../data/study";

import Story from "../../components/Stories/Item";
import Layout from "../../components/Layout";

import * as S from "./styles";

const GlobalStyle = createGlobalStyle`
  body {
    .bm-burger-button {
      span.bm-burger-bars {
        background: #FFF !important;
      }
    }

    #root main > header {
      background: #010023;
      * {
        color: #FFF;
        fill: #FFF;
      }
    }
  }
 `;

const Study = () => {
  const intl = useIntl();
  const config = useContext(AppContext);

  const [email, setEmail] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isRequested, setIsRequested] = useState(false);

  const emailIsValid = () => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (emailIsValid()) {
      const response = await fetch(`${config.API_URL}/request-study`, {
        method: "POST",
        mode: "cors",
        redirect: "follow",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });
      const result = await response.json();
      if (!result.error) {
        setIsRequested(true);
      }
    }
  };

  return (
    <Layout subFooter={false} newsletter={false}>
      <GlobalStyle />
      <Helmet>
        <title>Punktional - {intl.formatMessage({ id: "study.title" })}</title>
      </Helmet>
      <S.Wrapper>
        <S.Title>{intl.formatMessage({ id: "study.title" })}</S.Title>
        <S.Intro>
          {intl.formatMessage({ id: "study.intro" }, { br: <br /> })}
        </S.Intro>

        <S.Insta>
          {!isMobile && (
            <S.Chapters>
              {study.chapters.map((chap) => (
                <S.Chapter onClick={() => setCurrentIndex(chap.start - 1)}>
                  <span>
                    {chap.start < 10 ? `0${chap.start}` : chap.start} à{" "}
                    {chap.end < 10 ? `0${chap.end}` : chap.end} :
                  </span>{" "}
                  {chap.title}
                </S.Chapter>
              ))}
            </S.Chapters>
          )}

          <Insta
            stories={study.images}
            defaultInterval={3000}
            loop={true}
            width={isMobile ? 270 : 360}
            height={isMobile ? 480 : 640}
            onAllStoriesEnd={false}
            onStoryStart={(num) => setCurrentIndex(num)}
            currentIndex={currentIndex}
          />
        </S.Insta>

        <S.Content>
          <p>
            230 objets, autant d’histoires et d’anecdotes que nous vous avons
            racontées depuis 2 ans. Ces objets sont des iconiques : des objets
            cultes qui enflamment le marché du vintage, qu’on désire, s’arrache,
            recherche ou convoite (ça va de soit de vous les présenter et de
            comprendre pourquoi ils ont fait l’histoire/sont devenus des
            icônes)...
            <br />
            ...Ou alors ce sont de pures découvertes (on sautille de joie
            lorsqu’on met la main dessus, lorsqu’on creuse pour trouver les
            anecdotes et heureux de vous les partager et les faire connaître).
          </p>
          <p>
            Notre sélection d’objets est résolument éclectique et libre, guidée
            par notre volonté d’inspirer, de surprendre en cherchant de nouveaux
            créateurs, de permettre de mieux comprendre une époque.
          </p>
          <p>
            La fin d’année approchant, on voit fleurir les cahiers de tendances
            et autres prédictions pour le futur de nos intérieurs… Mais alors
            voilà chez Punktional, la tendance ça nous fait prendre nos jambes à
            notre coup, fuir dans la direction opposée (on a l’esprit de
            contradiction aussi) parce que cela uniformise les intérieurs avant
            de se retrouver figé dans un décor déjà démodé (car les tendances
            filent aussi vite que les saisons - et ma petite dame y a plus de
            saisons).
          </p>
          <p>
            On vous partage nos souhaits, nos envies.
            <br />
            Cette sélection de pièces “rangées” dans 4 catégories sont nos
            objets de convoitise.
          </p>
          <p>
            Et, ces catégories c’est en quelque sorte notre boussole chez
            Punktional_ un des ingrédients de notre ‘secret sauce’ pour choisir
            les objets à raconter
          </p>
        </S.Content>
      </S.Wrapper>

      <S.PdfWrapper>
        <S.PdfContent>
          <S.PdfTitle>
            Vous souhaitez recevoir le PDF complet de notre étude ?
          </S.PdfTitle>
          <p>Laissez-nous votre e-mail et nous vous la partageons.</p>

          {isRequested ? (
            <p>{intl.formatMessage({ id: "study.request.success" })}</p>
          ) : (
            <S.Form>
              <div>
                <S.Input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder={intl.formatMessage({
                    id: "inputs.email.placeholder",
                  })}
                />
              </div>
              <S.Submit
                type="submit"
                disabled={!emailIsValid()}
                onClick={handleSubmit}
              >
                {intl.formatMessage({ id: "newsletter.submit" })}
              </S.Submit>
            </S.Form>
          )}
        </S.PdfContent>
      </S.PdfWrapper>

      <S.Wrapper>
        <S.Objects>
          <S.SubTitle>
            {intl.formatMessage({ id: "study.more.title" })}
          </S.SubTitle>
          <S.Stories>
            {stories
              .filter((elt) => study.stories.includes(elt.slug))
              .map((story) => (
                <Story key={`story-${story.slug}`} story={story} />
              ))}
          </S.Stories>
        </S.Objects>
      </S.Wrapper>
    </Layout>
  );
};

export default Study;
