import React, { useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { NavLink, useHistory, useParams } from "react-router-dom";
import { useIntl } from "react-intl";
import { createGlobalStyle } from "styled-components";
import { DateTime } from "luxon";
import { isMobile } from "react-device-detect";

import episodes from "../../data/podcast";

import Layout from "../../components/Layout";
import Episode from "../../components/Podcast/Episode";
import Focus from "../../components/Podcast/Focus";
import List from "../../components/Podcast/List";

import * as S from "./styles";

const GlobalStyle = createGlobalStyle`
  body {
    .bm-burger-button {
      span.bm-burger-bars {
        background: #FFF !important;
      }
    }

    #root main > header {
      background: #010023;
      * {
        color: #FFF;
        fill: #FFF;
      }
    }
  }
 `;

const Podcast = () => {
  const intl = useIntl();
  const { slug } = useParams();
  const history = useHistory();

  const [episode, setEpisode] = useState();

  const sortedList = useMemo(
    () =>
      episodes
        .filter((elt) => DateTime.fromISO(elt.publishedAt) < DateTime.now())
        .sort((a, b) => {
          const dateA = DateTime.fromISO(a.publishedAt);
          const dateB = DateTime.fromISO(b.publishedAt);
          return dateA < dateB ? -1 : dateA > dateB ? 1 : 0;
        })
        .reverse(),
    [episodes]
  );

  useEffect(() => {
    if (!slug) {
      setEpisode(null);
      return;
    }

    const item = episodes.find(
      (elt) => elt.slug.toLowerCase() === slug.toLowerCase()
    );

    if (item) {
      setEpisode(item);
    } else {
      history.push("/podcast");
      setEpisode(null);
    }
  }, [slug]);

  const getTitle = () => {
    return `Punktional - ${
      episode
        ? `${episode.title}`
        : intl.formatMessage({ id: "podcast.title" }, { br: " " })
    }`;
  };

  return (
    <Layout subFooter={false}>
      <GlobalStyle />
      <Helmet>
        <title>{getTitle()}</title>
      </Helmet>
      <S.Wrapper>
        {episode ? (
          <Episode episode={episode} />
        ) : (
          <>
            <S.Title>
              {intl.formatMessage({ id: "podcast.title" }, { br: <br /> })}
            </S.Title>
            <S.Intro>
              {intl.formatMessage({ id: "podcast.intro" }, { br: <br /> })}
            </S.Intro>

            {!isMobile ? (
              <>
                <Focus episode={sortedList[0]} />
                <List episodes={sortedList.slice(1)} />
              </>
            ) : (
              <>
                {sortedList.map((elt, i) => (
                  <Focus key={`episode-${i}`} episode={elt} />
                ))}
              </>
            )}
          </>
        )}
      </S.Wrapper>
    </Layout>
  );
};

export default Podcast;
