import React from "react";
import { useIntl } from "react-intl";

import ChatIcon from "../images/Chat";
import LoveIcon from "../images/Love";

import * as S from "./styles";

const SubFooter = () => {
  const intl = useIntl();

  return (
    <S.Wrapper>
      <S.Content>
        <S.Block>
          <ChatIcon />
          <div>
            {intl.formatMessage(
              { id: "subfooter.chat" },
              {
                br: <br />,
                a: (...chunk) => (
                  <a href="mailto:hello@punktional.com">{chunk}</a>
                ),
              }
            )}
          </div>
        </S.Block>

        <S.Block>
          <LoveIcon />
          {intl.formatMessage(
            { id: "subfooter.delivery" },
            {
              br: <br />,
            }
          )}
        </S.Block>
      </S.Content>
    </S.Wrapper>
  );
};

export default SubFooter;
