import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";

import stories from "../../../data/stories";

import Story from "../../Stories/Item";
import ImageList from "../ImageList";

import * as S from "./styles";

const Article = ({ saga }) => {
  const intl = useIntl();

  const { content, cover, focus, images, resume, subtitle, title, contentFooter } = saga;

  return (
    <>
      <S.GoBack to="/saga">
        {intl.formatMessage({ id: "common.back" })}
      </S.GoBack>
      <S.Wrapper>
        <S.Cover src={cover} alt={title} />
        <S.Title>
          {title}
          <span dangerouslySetInnerHTML={{ __html: subtitle }} />
        </S.Title>
        <S.Intro dangerouslySetInnerHTML={{ __html: resume }} />
        <S.Article dangerouslySetInnerHTML={{ __html: content }} />

        {images && <ImageList images={images} />}

        {focus && (
          <S.Focus id="focus">
            <S.FTitle>{focus.title}</S.FTitle>
            <S.FIntro>{focus.intro}</S.FIntro>
            {focus.elements.map((elt, i) => (
              <S.FElt key={`focus-${i}`}>
                <img src={elt.image} />
                <p dangerouslySetInnerHTML={{ __html: elt.content }} />
              </S.FElt>
            ))}
          </S.Focus>
        )}

{contentFooter && <S.Article dangerouslySetInnerHTML={{ __html: contentFooter }} />}
      </S.Wrapper>

      <S.GoBack to="/saga">
        {intl.formatMessage({ id: "common.back" })}
      </S.GoBack>

      {saga.stories && (
        <S.Objects>
          <S.SubTitle>
            {intl.formatMessage({ id: "saga.objects.title" })}
          </S.SubTitle>
          <S.Stories>
            {stories
              .filter((elt) => saga.stories.includes(elt.slug))
              .map((story) => (
                <Story key={`story-${story.slug}`} story={story} />
              ))}
          </S.Stories>
        </S.Objects>
      )}
    </>
  );
};

Article.propTypes = {
  saga: PropTypes.object.isRequired,
};

export default Article;
