import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 150px 0;

  @media (max-width: 768px) {
    margin: 50px 0;
  }
`;

export const T = styled.div``;
