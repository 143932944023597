import React from "react";
import { useIntl } from "react-intl";

import Instagram from "../images/Instagram";
import Linkedin from "../images/Linkedin";

import * as S from "./styles";

const Socials = [
  {
    name: "instagram",
    url: "https://www.instagram.com/punktional_/",
    icon: Instagram,
  },
  {
    name: "linkedin",
    url: "https://www.linkedin.com/company/punktional",
    icon: Linkedin,
  },
];

const Links = [
  { name: "contact", link: "mailto:hello@punktional.com" },
  { name: "about", link: "/a-propos" },
  { name: "podcast", link: "/podcast" },
  { name: "newsletter", link: "/newsletter" },
  { name: "studio", link: "https://studio.punktional.com" },
];

const Footer = () => {
  const intl = useIntl();

  return (
    <S.Wrapper>
      <S.Content>
        <S.Social>
          {Socials.map((elt) => {
            const Icon = elt.icon;
            return (
              <li key={`footer.social.${elt.name}`}>
                <a href={elt.url} target="_blank" rel="noreferrer">
                  <Icon />
                </a>
              </li>
            );
          })}
        </S.Social>

        <S.Links>
          <ul>
            {Links.map((elt) => (
              <li key={`footer.${elt.name}`}>
                <a href={elt.link}>
                  {intl.formatMessage({
                    id: `footer.${elt.name}`,
                  })}
                </a>
              </li>
            ))}
          </ul>
        </S.Links>

        <S.Legals>{intl.formatMessage({ id: "footer.copyright" })}</S.Legals>
      </S.Content>
    </S.Wrapper>
  );
};

export default Footer;
