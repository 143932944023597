import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { DateTime } from "luxon";

import * as S from "./styles";

const Focus = ({ episode }) => {
  const intl = useIntl();

  return (
    <S.Wrapper>
      <S.Visu to={`/podcast/${episode.slug}`}>
        <img
          src={episode.images.cover}
          alt={`${episode.season} - ${episode.episodeNumber}${episode.title}`}
        />
      </S.Visu>

      <S.Infos>
        <S.Metas>
          <S.Season>
            {intl.formatMessage(
              { id: "podcast.item.season" },
              {
                name: episode.season,
                num: episode.episodeNumber,
              }
            )}
          </S.Season>
          <S.Date>
            {DateTime.fromISO(episode.publishedAt).toLocaleString(
              DateTime.DATE_SHORT
            )}
          </S.Date>
        </S.Metas>
        <S.Title>{episode.title}</S.Title>
        <S.Intro dangerouslySetInnerHTML={{ __html: episode.preview }} />

        <S.Footer>
          <S.Link to={`/podcast/${episode.slug}`}>
            {intl.formatMessage({ id: "podcast.item.button" })}
          </S.Link>
        </S.Footer>
      </S.Infos>
    </S.Wrapper>
  );
};

Focus.propTypes = {
  episode: PropTypes.object.isRequired,
};

export default Focus;
