import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { DateTime } from "luxon";

import MakersIcon from "../../images/Maker";

import * as S from "./styles";

const Placeholder = ({ isPreview = false, product }) => {
  const intl = useIntl();
  const launchDate = DateTime.fromISO(product.launch);

  return (
    <S.Wrapper>
      <S.Preview>
        <img src={product.placeholder} alt={""} />
        <S.Flocking>
          <MakersIcon />
        </S.Flocking>

        {isPreview && (
          <S.Date>
            {intl.formatMessage(
              { id: "placeholder.date" },
              {
                date: launchDate
                  .setLocale("fr")
                  .toLocaleString({ month: "long", day: "numeric" }),
              }
            )}
          </S.Date>
        )}
      </S.Preview>

      {!isPreview && (
        <S.Title>
          {intl.formatMessage(
            { id: "placeholder.text" },
            {
              date: launchDate
                .setLocale("fr")
                .toLocaleString({ month: "long", day: "numeric" }),
            }
          )}
        </S.Title>
      )}
    </S.Wrapper>
  );
};

Placeholder.propTypes = {
  isPreview: PropTypes.bool,
  product: PropTypes.object.isRequired,
};

export default Placeholder;
