import styled from "styled-components";

import * as C from "../../components/commons/styles";

export const Wrapper = styled(C.Content)`
  margin: 55px auto 200px auto;
  flex-direction: column;
  text-align: center;

  p {
    margin: 0 auto;
    max-width: 650px;
  }

  @media (max-width: 1280px) {
    padding: 0 35px;
  }
  @media (max-width: 768px) {
    margin: 20px 20px 100px 20px;
    padding: 0;
  }
`;

export const Link = styled(C.Button)`
  margin-top: 100px;
  align-self: center;

  @media (max-width: 768px) {
    width: auto;
    margin: 40px auto 0 auto;
    padding: 8px 21px;
    transform: none;
    text-align: center;

    font-size: 12px;
    line-height: 18px;
  }
`;
