import React, { useState } from "react";
import Modal from "react-modal";
import { isMobile } from "react-device-detect";

import CloseIcon from "../images/Close";

import * as S from "./styles";

const customStyles = {
  content: {
    height: "fit-content",
    padding: 0,
    border: "none",
    zIndex: 20,
    borderRadius: 5,
    border: "1px solid #010023",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "552px",
  },
};

const customMobileStyles = {
  content: {
    height: "fit-content",
    padding: 0,
    border: "none",
    zIndex: 20,
    borderRadius: 5,
    border: "1px solid #010023",
    inset: "10px",
  },
};

Modal.setAppElement("#root");

const Newsletter = () => {
  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = (e) => {
    if (e) e.preventDefault();
    setShowModal(false);
  };

  return (
    <>
      <S.Wrapper>
        <S.Content>
          <S.Header>
            <S.Title>La newsletter</S.Title>
            <S.Intro>
             Tous les mardis, un digest design à la sauce Punktional, avec supplément inspi 🍦
            </S.Intro>
            <S.Link onClick={(e) => setShowModal(true)}>
              S'abonner à la newsletter
            </S.Link>
          </S.Header>
        </S.Content>
      </S.Wrapper>
      <S.Background />

      <Modal
        isOpen={showModal}
        onRequestClose={handleCloseModal}
        style={isMobile ? customMobileStyles : customStyles}
        shouldCloseOnOverlayClick
      >
        <S.Close onClick={handleCloseModal}>
          <CloseIcon color="#fff" />
        </S.Close>

        <S.Img>
          <img src="/images/newsletter/header.jpg" alt="" />
        </S.Img>
        <S.Form />
      </Modal>
    </>
  );
};

export default Newsletter;
