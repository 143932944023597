import React from "react";
import PropTypes from "prop-types";
import { DateTime } from "luxon";

import EyeIcon from "../../images/Eye";

import * as S from "./styles";

const Item = ({ article }) => {
  return (
    <S.Wrapper to={`/saga/${article.slug}`}>
      <S.Preview>
        <S.Hover>
          <EyeIcon />
        </S.Hover>
        <img src={article.cover} alt={article.title} />
      </S.Preview>

      <S.Content>
        <S.Data>
          {article.kind} -{" "}
          {DateTime.fromISO(article.publishedAt)
            .setLocale("fr")
            .toLocaleString(DateTime.DATE_SHORT)}
        </S.Data>

        <S.Title>
          {article.title}
          <br />
          <span dangerouslySetInnerHTML={{ __html: article.subtitle }} />
        </S.Title>
      </S.Content>
    </S.Wrapper>
  );
};

Item.propTypes = {
  article: PropTypes.object.isRequired,
};

export default Item;
