import React from "react";
import { useIntl } from "react-intl";
import { DateTime } from "luxon";
import { isMobile } from "react-device-detect";

import Stories from "../../../data/stories";

import * as S from "./styles";

const Home = () => {
  const intl = useIntl();

  const last4 = Stories.filter((elt) => elt.preview)
    .sort((a, b) => {
      const dateA = DateTime.fromISO(a.publishedAt);
      const dateB = DateTime.fromISO(b.publishedAt);
      return dateA < dateB ? -1 : dateA > dateB ? 1 : 0;
    })
    .reverse()
    .slice(0, 4);

  return (
    <S.Wrapper>
      {isMobile && (
        <S.Title>{intl.formatMessage({ id: "stories.home.title" })}</S.Title>
      )}
      <S.Images>
        {last4.map((elt, i) => (
          <S.Img src={elt.preview} alt={elt.title} key={`img-${i}`} />
        ))}
      </S.Images>

      {!isMobile && (
        <S.Title>{intl.formatMessage({ id: "stories.home.title" })}</S.Title>
      )}
      <p>
        {intl.formatMessage(
          { id: "stories.home.intro" },

          {
            br: <br />,
          }
        )}
      </p>

      <S.Footer>
        <S.Link to="/stories">
          {intl.formatMessage({ id: "stories.home.button" })}
        </S.Link>
      </S.Footer>
    </S.Wrapper>
  );
};

export default Home;
