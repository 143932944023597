import styled from "styled-components";
import { rgba } from "polished";

import * as C from "../commons/styles";

export const Wrapper = styled.section`
  padding: 48px 0;
  background: ${({ theme }) => rgba(theme.blue, 0.02)};

  @media (max-width: 768px) {
    margin-bottom: 50px;
    padding: 28px 0;
  }
`;

export const Content = styled(C.Content)``;

export const Block = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  a {
    color: inherit;
    &:hover {
      text-decoration: none;
    }
  }

  @media (max-width: 768px) {
    margin: 20px 0;
    padding: 0 30px;
    font-size: 14px;
    line-height: 20px;
    svg {
      margin: 0 20px 0 0 !important;
    }
  }

  svg {
    margin: 0 60px 0 0;
    height: 60px;
  }

  &:first-child {
    flex-direction: row-reverse;
    text-align: right;
    svg {
      margin: 0 0 0 60px;
    }
    @media (max-width: 768px) {
      svg {
        margin: 0 0 0 20px !important;
      }
    }
  }
`;
