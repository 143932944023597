import styled from "styled-components";

import * as C from "../../commons/styles";
import * as I from "../Item/styles";
import * as P from "../Placeholder/styles";

export const Wrapper = styled(C.Content)`
  margin: 55px auto;
  flex-direction: column;

  h1 {
    margin: 0 0 23px 0;
    font-size: 20px;
    line-height: 30px;
    font-style: normal;
  }

  @media (max-width: 1280px) {
    padding: 0 35px;
  }
  @media (max-width: 768px) {
    padding: 0 20px;

    h1 {
      font-size: 14px;
      font-weight: bold;
      line-height: 21px;
    }
  }
`;

export const List = styled.div`
  display: flex;
  flex-direction: row;
  text-align: center;

  > a,
  > div {
    flex: 0 25%;
  }

  > a + a,
  > a + div,
  > div + div {
    margin-left: 20px;
  }

  @media (max-width: 768px) {
    flex-wrap: wrap;

    > a,
    > div {
      width: 100%;
      flex: 0 47%;
      margin: 0 0 20px 10px !important;
      &:nth-child(odd) {
        margin: 0 10px 20px 0 !important;
      }
    }

    ${I.Preview} {
      margin: 0;
    }

    ${I.Flocking}, ${P.Flocking} {
      bottom: 5px;
      right: 5px;
      width: 30px;
      height: 30px;
    }
  }
`;
