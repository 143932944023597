import styled from "styled-components";

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto 1fr;
  gap: 0;
  grid-template-areas:
    "intro intro"
    "podcast stories"
    "newsletter newsletter"
    "saga saga"
    "next next";
  margin: 0 0 80px 0;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto;
    grid-template-areas:
      "intro"
      "podcast"
      "stories"
      "newsletter"
      "saga"
      "next";
  }
`;

export const Intro = styled.div`
  grid-area: intro;
  margin-bottom: 42px;
  text-align: center;

  p {
    font-weight: bold;
    font-size: 18px;
    line-height: 27px;
    strong {
      font-size: 20px;
      font-weight: normal;
      line-height: 29px;
      font-family: "Permanent Marker";
    }
  }

  @media (max-width: 768px) {
    p {
      font-size: 12px;
      line-height: 17px;
      strong {
        font-size: 12px;
        line-height: 18px;
      }
    }
  }
`;

export const Podcast = styled.div`
  grid-area: podcast;
  display: flex;
  justify-content: flex-end;
  background: ${({ theme }) => theme.blue};
`;

export const Stories = styled.div`
  grid-area: stories;
  display: flex;
  justify-content: flex-start;

  @media (max-width: 768px) {
    justify-content: center;
  }
`;

export const Newsletter = styled.div`
  position: relative;
  grid-area: newsletter;
  display: flex;
  justify-content: center;
  margin: 84px 0 0 0;
  background: ${({ theme }) => theme.kingBlue || "#002fa7"};

  @media (max-width: 768px) {
    margin: 0;
  }
`;

export const Sagas = styled.div`
  grid-area: saga;
  display: flex;
  padding: 50px 0;
  justify-content: flex-start;
  background: rgba(1, 0, 35, 0.02);

  @media (max-width: 768px) {
    padding: 0;
  }
`;

export const Next = styled.div`
  grid-area: next;
  display: flex;
  margin: 60px 0 0 0;
`;
