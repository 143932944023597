import styled from "styled-components";
import { rgba } from "polished";
import { Link } from "react-router-dom";

export const SubTitle = styled.h2``;

export const Hover = styled.div`
  display: none;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => rgba(theme.blue, 0.39)};

  svg {
    width: 20%;
  }
`;

export const Wrapper = styled(Link)`
  margin: 0 0 40px 0;
  padding: 0 0 20px 0;
  color: ${({ theme }) => theme.blue};
  text-decoration: none;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: ${({ theme }) => theme.white};

  &:hover {
    ${Hover} {
      display: flex;
    }
  }

  @media (max-width: 768px) {
    margin: 0;
  }
`;

export const Content = styled.div`
  padding: 20px;
`;

export const Title = styled.div`
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
`;

export const Data = styled.div`
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  text-transform: uppercase;
`;

export const Preview = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  overflow: hidden;
￼ align-items: center;
  cursor: pointer;
  flex-direction: column;

  img {
    width: 100%;
    max-height: 358px;
    object-fit: cover;
  }
`;
