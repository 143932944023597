import React, { useState } from "react";
import PropTypes from "prop-types";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

import * as S from "./styles";

const ImageList = ({ images }) => {
  const [diapo, setDiapo] = useState({ isOpen: false });

  const handleOpenDiapo = (i) => {
    setDiapo({ isOpen: true, index: i });
  };

  return (
    <S.Wrapper>
      {images.map((img, i) => (
        <S.Image
          key={`img-${i}`}
          onClick={() => {
            handleOpenDiapo(i);
          }}
          className={img.wide ? 'wide' : ''}
        >
          <img src={img.path} alt={`${img.title} - ${img.credit}`} />
          <p>{img.title}</p>
        </S.Image>
      ))}

      {diapo.isOpen && (
        <Lightbox
          enableZoom={false}
          mainSrc={images[diapo.index].path}
          imageTitle={images[diapo.index].title}
          imageCaption={images[diapo.index].credit}
          nextSrc={images[(diapo.index + 1) % images.length].path}
          prevSrc={
            images[(diapo.index + images.length - 1) % images.length].path
          }
          onCloseRequest={() => setDiapo({ isOpen: false })}
          onMovePrevRequest={() =>
            setDiapo({
              ...diapo,
              index: (diapo.index + images.length - 1) % images.length,
            })
          }
          onMoveNextRequest={() =>
            setDiapo({
              ...diapo,
              index: (diapo.index + images.length + 1) % images.length,
            })
          }
        />
      )}
    </S.Wrapper>
  );
};

ImageList.propTypes = {
  images: PropTypes.array.isRequired,
};

export default ImageList;
