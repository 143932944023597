import styled from "styled-components";

import * as C from "../../components/commons/styles";

export const Wrapper = styled(C.Content)`
  margin: 55px auto;
  flex-direction: column;
  text-align: center;

  p {
    margin: 0 auto;
    max-width: 650px;
  }

  @media (max-width: 1280px) {
    padding: 0 35px;
  }
  @media (max-width: 768px) {
    margin: 20px;
    padding: 0;
  }
`;

export const List = styled.section``;
