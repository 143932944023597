import React, { useState } from "react";
import PropTypes from "prop-types";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";

import EyeIcon from "../../images/Eye";

import * as S from "./styles";

const Item = ({ focus = false, story }) => {
  const [isHover, setIsHover] = useState(false);
  const { slug } = useParams();
  const history = useHistory();
  const query = new URLSearchParams(useLocation().search);

  const handleClick = (e) => {
    e.preventDefault();
    const search = query.get("search");
    history.push(`/stories/${story.slug}${search ? `?search=${search}` : ""}`);
  };

  const getImage = () => {
    const index = slug === story.slug || isHover || focus ? 1 : 0;
    return story.images[index];
  };

  return (
    <S.Wrapper
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      onClick={handleClick}
      href={`/stories/${story.slug}`}
    >
      <S.Preview>
        <S.Hover>
          <EyeIcon />
        </S.Hover>
        <LazyLoadImage
          alt={`${story.object} - ${story.designer}`}
          src={getImage()}
        />
      </S.Preview>
      <S.Object>{story.object}</S.Object>
      <S.Designer>{story.designer}</S.Designer>
      <S.Date>{story.creationDate}</S.Date>
    </S.Wrapper>
  );
};

Item.propTypes = {
  focus: PropTypes.bool,
  story: PropTypes.object.isRequired,
};

export default Item;
