import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Link, NavLink } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import Burger from "react-burger-menu";
import { isMobile } from "react-device-detect";

import Logo from "../images/Logo";

import * as S from "./styles";

const NavLeft = [{ name: "stories", link: "/stories" }];

const NavRight = [
  { name: "newsletter", link: "/newsletter" },
  { name: "saga", link: "/saga" },
];

const Header = () => {
  const intl = useIntl();
  const [isSticky, setSticky] = useState(false);

  useEffect(() => {
    document.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    const scrolled = document.scrollingElement.scrollTop;
    setSticky(scrolled > 70);
  };

  return (
    <S.Wrapper pageWrapId="root" className={isSticky ? "sticky" : ""}>
      <S.Content>
        {!isMobile && (
          <S.NavLeft>
            <ul>
              {NavLeft.map((elt) => (
                <li key={`header-${elt.name}`}>
                  <NavLink to={elt.link} activeClassName="active">
                    {intl.formatMessage({ id: `header.nav.${elt.name}` })}
                  </NavLink>
                </li>
              ))}
            </ul>
          </S.NavLeft>
        )}
        <S.Logo>
          <Link to="/">
            <Logo />
          </Link>
        </S.Logo>

        {!isMobile && (
          <S.NavRight>
            <ul>
              {NavRight.map((elt) => (
                <li key={`header-${elt.name}`}>
                  <NavLink
                    to={elt.link}
                    activeClassName="active"
                    className={elt.name}
                  >
                    {intl.formatMessage({ id: `header.nav.${elt.name}` })}
                  </NavLink>
                </li>
              ))}
            </ul>
          </S.NavRight>
        )}

        {isMobile && (
          <Burger.slide right width="100vw" disableAutoFocus>
            {[...NavLeft, ...NavRight].map((elt) => (
              <li key={`nav-${elt.name}`}>
                <NavLink
                  to={elt.link}
                  activeClassName="active"
                  className={elt.name}
                >
                  {intl.formatMessage({ id: `header.nav.${elt.name}` })}
                </NavLink>
              </li>
            ))}
          </Burger.slide>
        )}
      </S.Content>
      <ReactTooltip />
    </S.Wrapper>
  );
};

export default Header;
