import React, { useContext } from "react";
import PropTypes from "prop-types";
import { ThemeContext } from "styled-components";

const Linkedin = ({ color }) => {
  const theme = useContext(ThemeContext);
  const fillColor = color ? color : theme.blue;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 47">
      <path
        fill={fillColor}
        d="M39.5833 0H10.4167C4.66458 0 0 4.40544 0 9.83796V37.3843C0 42.8168 4.66458 47.2222 10.4167 47.2222H39.5833C45.3375 47.2222 50 42.8168 50 37.3843V9.83796C50 4.40544 45.3375 0 39.5833 0ZM16.6667 37.3843H10.4167V15.7407H16.6667V37.3843ZM13.5417 13.2458C11.5292 13.2458 9.89583 11.6914 9.89583 9.775C9.89583 7.85856 11.5292 6.30417 13.5417 6.30417C15.5542 6.30417 17.1875 7.85856 17.1875 9.775C17.1875 11.6914 15.5562 13.2458 13.5417 13.2458ZM41.6667 37.3843H35.4167V26.3579C35.4167 19.731 27.0833 20.2328 27.0833 26.3579V37.3843H20.8333V15.7407H27.0833V19.2135C29.9917 14.1253 41.6667 13.7495 41.6667 24.0853V37.3843Z"
      />
    </svg>
  );
};

Linkedin.propTypes = {
  color: PropTypes.string,
};

export default Linkedin;
