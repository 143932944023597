import React from "react";
import { Helmet } from "react-helmet";
import { DateTime } from "luxon";

import Layout from "../../components/Layout";

import episodes from "../../data/newsletters";
import * as S from "./styles";

const Episodes = () => (
  <Layout subFooter={false} newsletter={false}>
    <Helmet>
      <title>Punktional - Les épisodes précédents</title>
    </Helmet>
    <S.Wrapper>
      <S.Content>
        <img src="/images/newsletter/cover-nl.png" alt="" />
        <h1>Les épisodes précédents</h1>
        {episodes.map((episode) => (
          <S.Episode key={episode.publishedAt}>
            <span>
              {DateTime.fromISO(episode.publishedAt).toLocaleString()}
            </span>
            <a href={episode.url} target="_blank" rel="noreferrer">
              {episode.title}
            </a>
          </S.Episode>
        ))}

        <S.Form id="sabonner" />
      </S.Content>
    </S.Wrapper>
  </Layout>
);

export default Episodes;
