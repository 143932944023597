import styled from "styled-components";

import * as C from "../../components/commons/styles";

export const Wrapper = styled(C.Content)`
  margin: 100px auto;

  img {
    margin-left: auto;
    max-width: 550px;
    height: 100%;
  }

  @media (max-width: 768px) {
    margin: 20px 0;
    flex-direction: column-reverse;

    img {
      width: 100%;
    }
  }
`;

export const Content = styled.section`
  width: 50%;
  padding-right: 50px;
  font-size: 14px;
  line-height: 21px;
  color: ${({ theme }) => theme.white};

  a {
    font-weight: bold;
    color: inherit;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

  @media (max-width: 768px) {
    width: 100%;
    padding: 150px 20px 0px 20px;
  }
`;

export const Header = styled.header`
  margin-bottom: 20px;
`;

export const Title = styled.h2`
  font-size: 20px;
  line-height: 30px;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 14px;
    font-weight: bold;
    line-height: 21px;
  }
`;

export const Intro = styled.p`
  font-size: 16px;
  line-height: 20px;
  font-weight: normal;

  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 21px;
  }
`;

export const Form = styled.form`
  display: flex;
  height: 50px;

  > div {
    display: flex;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    height: auto;
    margin: 20px 0;
  }

  svg {
    height: 50px;
    width: auto;
  }
`;

export const Input = styled.input`
  flex: 1;
  padding: 0 15px;
  border: none;
  min-width: 400px;
  border-bottom: 1px solid ${({ theme }) => theme.white};
  font-size: 16px;
  font-weight: 300;
  line-height: 30px;
  font-style: italic;
  color: ${({ theme }) => theme.white};
  outline: none;

  &::placeholder {
    color: ${({ theme }) => theme.white};
  }

  @media (max-width: 768px) {
    min-width: calc(100vw - 130px);
    padding: 21px 0 0 10px;
    font-size: 12px;
    line-height: 18px;
  }
`;

export const Submit = styled.button`
  margin: 0 0 0 14px;
  padding: 10px 48px;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  color: ${({ theme }) => theme.white};
  text-transform: uppercase;
  background: none;
  border: 1px solid ${({ theme }) => theme.white};
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => theme.white};
    color: #e30613;
  }

  @media (max-width: 768px) {
    margin: 20px auto;
    padding: 6px 25px;
    font-size: 12px;
    line-height: 18px;
  }
`;
