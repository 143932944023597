import styled from "styled-components";
import { rgba } from "polished";

export const Form = styled.form`
  padding: 40px;

  @media (max-width: 768px) {
    height: auto;
    margin: 20px 0;
  }
`;

export const Title = styled.p`
  margin: 0 0 20px 0;
  font-size: 20px;
  font-weight: 300;
  line-height: 30px;
  font-style: italic;
`;

export const Label = styled.label`
  display: block;
  margin-top: 20px;
  font-size: 16px;
  font-weight: 500;

  span {
    display: block;
    margin-bottom: 5px;
    font-weight: 300;
    font-style: italic;
  }
`;

export const Input = styled.input`
  flex: 1;
  padding: 0 15px;
  margin-bottom: 20px;
  min-width: 400px;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.blue};
  font-size: 16px;
  font-weight: 300;
  line-height: 36px;
  border-radius: 4px;
  color: ${({ theme }) => theme.blue};
  outline: none;
  background: ${({ theme }) => rgba(theme.white, 0.6)};

  &::placeholder {
    color: ${({ theme }) => theme.blue};
  }

  @media (max-width: 768px) {
    min-width: 0;
    padding: 7px 0 7px 10px;
    font-size: 12px;
    line-height: 18px;
  }
`;

export const Select = styled.select`
  flex: 1;
  padding: 0 15px;
  min-width: 400px;
  border: 1px solid ${({ theme }) => theme.blue};
  font-size: 16px;
  font-weight: 300;
  line-height: 30px;
  font-style: italic;
  color: ${({ theme }) => theme.blue};
  outline: none;
  background: ${({ theme }) => rgba(theme.white, 0.6)};

  &:invalid {
    color: gray;
  }
  option {
    color: ${({ theme }) => theme.blue};
  }
`;

export const Submit = styled.button`
  display: block;
  margin: 30px auto 0 auto;
  padding: 10px 48px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.white};
  text-transform: capitalize;
  background: ${({ theme }) => theme.blue};
  border: 1px solid ${({ theme }) => theme.blue};
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => theme.white};
    color: ${({ theme }) => theme.blue};
  }

  @media (max-width: 768px) {
    padding: 6px 25px;
    font-size: 12px;
    line-height: 18px;
  }
`;
