import React from "react";
import { useIntl } from "react-intl";

import * as S from "./styles";

const Home = () => {
  const intl = useIntl();

  return (
    <S.Wrapper>
      <img src="/images/podcast/cover-podcast.gif" alt="Podcast" />
      <S.Title>{intl.formatMessage({ id: "podcast.home.title" })}</S.Title>
      <p>
        {intl.formatMessage(
          { id: "podcast.home.intro" },

          {
            br: <br />,
          }
        )}
      </p>

      <S.Footer>
        <S.Link to="/podcast">
          {intl.formatMessage({ id: "podcast.home.button" })}
        </S.Link>
      </S.Footer>
    </S.Wrapper>
  );
};

export default Home;
