import styled from "styled-components";

export const Wrapper = styled.section`
  margin-top: 50px;
`;

export const Header = styled.header`
  text-align: left;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const DeleteBtn = styled.div`
  line-height: 0;
  svg {
    height: 24px;
    cursor: pointer;
  }
`;

export const Search = styled.input`
  min-width: 400px;
  padding: 0 10px;
  font-style: italic;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: rgba(16, 0, 35, 0.6);

  @media (max-width: 768px) {
    min-width: 310px;
  }
`;

export const List = styled.section`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin-top: 40px;
  grid-gap: 40px;

  @media (max-width: 1280px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const NoResults = styled.p`
  text-align: center;
  font-size: 16px;
  line-height: 24px;
`;
