const Podcast = [
  {
    publishedAt: "2022-01-01",
    slug: "episode-1",
    season: "01 Design italien",
    episodeNumber: 1,
    title: "La chaise Universale de Joe Colombo",
    designer: "Joe Colombo",
    object: "Universale",
    flux: "5u8ZTh45M7GLUX75834Zsn",
    links: {
      apple:
        "https://podcasts.apple.com/fr/podcast/la-chaise-universale-de-joe-colombo-%C3%A9pisode-1/id1601115221?i=1000545437757",
      spotify:
        "https://open.spotify.com/episode/5u8ZTh45M7GLUX75834Zsn?si=KAVb-JzERYyIKQ5Kk0c5-Q",
      deezer: "https://deezer.page.link/drKebXuM9tjAU3aC9",
    },
    preview:
      "C’est une chaise aux couleurs pop et toute en plastique. Elle a un trou rectangulaire de la largeur d’une main en bas du dossier...",
    intro:
      "C’est une chaise aux couleurs pop et toute en plastique. Elle a un trou rectangulaire de la largeur d’une main en bas du dossier...Cette chaise c’est l'Universale de Joe Colombo !<br/>Il se trouve que c’est le premier objet que nous avons raconté sur Punktional. Et si on l’a choisie à nouveau pour ouvrir le bal de notre série italienne c’est parce qu’elle est la première assise en plastique moulé vraiment produite en série.",
    content: "",
    images: {
      cover: "/images/podcast/saison01/episode01/objet.jpg",
      object: "/images/podcast/saison01/episode01/objet.jpg",
      designer: "/images/podcast/saison01/episode01/designer.jpg",
    },
    stories: ["universale", "sferico", "boby"],
    sagas: [],
    credits: {
      realisation: "Mathilde Maître et Thomas Borie",
      edition: "Corentin Brasart",
    },
  },
  {
    publishedAt: "2022-01-02",
    slug: "episode-2",
    season: "01 Design italien",
    episodeNumber: 2,
    title: "Le Componibili d’Anna Castelli Ferrieri",
    designer: "Anna Castelli Ferrieri",
    object: "Componibili",
    flux: "1xuK2zxD3zflYlB5LuCi3e",
    links: {
      apple:
        "https://podcasts.apple.com/fr/podcast/le-componibili-danna-castelli-ferrieri-%C3%A9pisode-2/id1601115221?i=1000546720745",
      spotify:
        "https://open.spotify.com/episode/1xuK2zxD3zflYlB5LuCi3e?si=nLhyJqEfSmqCSDD4xCjeRw",
      deezer: "https://deezer.page.link/fsLbV8Q4udCzKxfZ8",
    },
    preview:
      "Vous voyez une boîte de conserve ? C’est la forme de cet objet. Il est rond, complètement rond, avec des tiroirs...",
    intro:
      "Vous voyez une boîte de conserve ? C’est la forme de cet objet. Il est rond, complètement rond, avec des tiroirs.<br/>Aujourd’hui, on vous parle du Componibili, le meuble de rangement qui symbolise à lui seul la rupture qui s’est opérée dans le design dans les années 60.  Tout ça parce que sa designer Anna Castelli Ferrieri envoie valser les angles droit.<br/>Vous en doutez ou vous pensez que c’est exagéré ? Pas du tout !",
    content: "",
    images: {
      cover: "/images/podcast/saison01/episode02/objet.jpg",
      object: "/images/podcast/saison01/episode02/objet.jpg",
      designer: "/images/podcast/saison01/episode02/designer.jpg",
    },
    stories: ["mobili-4970-84-ou-componobili"],
    sagas: ["anna-castelli-ferrieri-et-giulio-castelli"],
  },
  {
    publishedAt: "2022-01-11",
    slug: "episode-3",
    season: "01 Design italien",
    episodeNumber: 3,
    title: "La Superleggera de Gio Ponti",
    designer: "Gio Ponti",
    object: "Superleggera",
    flux: "12JSA2UbwxUk4XrdFp3tov",
    links: {
      apple:
        "https://podcasts.apple.com/fr/podcast/la-superleggera-de-gio-ponti-%C3%A9pisode-3/id1601115221?i=1000547522536",
      spotify:
        "https://open.spotify.com/episode/12JSA2UbwxUk4XrdFp3tov?si=070cf07f0ae54848",
      deezer: "https://deezer.page.link/xKXVdhNKbdWX7Ku89",
    },
    preview:
      "1,7 kg c’est le poids de cet objet, soit la chaise en bois la plus légère du monde !",
    intro:
      "1,7 kg c’est le poids de cet objet, soit la chaise en bois la plus légère du monde !<br/>J’ai nommé la Superleggera. Cela ne vous évoque peut-être pas grand-chose… Mais sachez qu’une chaise en bois massif pèse plutôt aux alentours de 5 kg. Celle-ci vous pouvez donc la porter à bout de bras sans faiblir. Comment est-ce possible de diviser son poids par 3 ou presque ? C’est le génie du maestro Gio Ponti, homme de tous les superlatifs et designer de cette chaise.",
    content: "",
    images: {
      cover: "/images/podcast/saison01/episode03/objet.jpg",
      object: "/images/podcast/saison01/episode03/objet.jpg",
      designer: "/images/podcast/saison01/episode03/designer.jpg",
    },
    stories: ["superleggera"],
    sagas: [],
  },
  {
    publishedAt: "2022-01-18",
    slug: "episode-4",
    season: "01 Design italien",
    episodeNumber: 4,
    title: "La Lampe Chiara de Mario Bellini",
    designer: "Mario Bellini",
    object: "Chiara",
    flux: "6BFGL1dVoSk9oexf4MF0Gs",
    links: {
      apple:
        "https://podcasts.apple.com/fr/podcast/chiara-de-mario-bellini-%C3%A9pisode-4/id1601115221?i=1000548200347",
      spotify:
        "https://open.spotify.com/episode/6BFGL1dVoSk9oexf4MF0Gs?si=824642e9936d43ae",
      deezer: "https://deezer.page.link/zqmnbVHyQvdu3nx39",
    },
    preview:
      "Prenez des ciseaux, un grand morceau de carton et découpez une forme que l’on pourrait rouler en un cylindre avec un large chapeau sur le dessus.",
    intro:
      "Prenez des ciseaux, un grand morceau de carton et découpez une forme que l’on pourrait rouler en un cylindre avec un large chapeau sur le dessus. Placez une ampoule dans la base et voici un luminaire.<br/><br/>C’est presque aussi simplement que Mario Bellini a designé Chiara, un grand luminaire à poser au sol qui diffuse la lumière avec grâce. Aujourd’hui je vous raconte comment ce designer a dessiné plus qu’un objet mais littéralement fait de la lumière. De la magie ? Pas loin…",
    content: "",
    images: {
      cover: "/images/podcast/saison01/episode04/objet.jpg",
      object: "/images/podcast/saison01/episode04/objet.jpg",
      designer: "/images/podcast/saison01/episode04/designer.jpg",
    },
    stories: ["lampe-chiara"],
    sagas: [],
  },
  {
    publishedAt: "2022-01-25",
    slug: "episode-5",
    season: "01 Design italien",
    episodeNumber: 5,
    title: "Le Tabouret Mezzadro d'Achille & Pier Giacomo Castiglioni",
    designer: "Achille & Pier Giacomo Castiglioni",
    object: "Mezzadro",
    flux: "4uTZXO32jgYbdfjgl9qiH9",
    links: {
      apple:
        "https://podcasts.apple.com/fr/podcast/le-tabouret-mezzadro-des-castiglioni-episode-5/id1601115221?i=1000548908790",
      spotify:
        "https://open.spotify.com/episode/4uTZXO32jgYbdfjgl9qiH9?si=oqrH0DS6R-ykTULvWhHlhg",
      deezer: "https://deezer.page.link/hEK5AxRXoBHdiZ1t5",
    },
    preview:
      "Un siège de tracteur en guise d’assise… Vous allez me dire encore un designer qui se prend pour un artiste...",
    intro:
      "Un siège de tracteur en guise d’assise… Vous allez me dire encore un designer qui se prend pour un artiste avec un objet sculpture, un ready-made à la Marcel Duchamp qui a plus sa place dans un musée que dans un appartement !<br/><br/>Ce serait bien mal connaître Achille Castiglioni et son frère Pier Giacomo, cette fratrie de designers des plus ingénieux. C’est donc avec cet humour, voire cette espièglerie qui les caractérisent qu’ils proposent le tabouret Mezzadro.<br/><br/>Aujourd’hui on vous raconte comment, en bons designers industriels, ils se sont vraiment préoccupés de nos fesses.",
    content: "",
    images: {
      cover: "/images/podcast/saison01/episode05/objet.jpg",
      object: "/images/podcast/saison01/episode05/objet.jpg",
      designer: "/images/podcast/saison01/episode05/designer.jpg",
    },
    stories: ["mezzadro", "sella", "rr126", "cento-3"],
    sagas: [],
  },
  {
    publishedAt: "2022-02-01",
    slug: "episode-6",
    season: "01 Design italien",
    episodeNumber: 6,
    title: "Le vase Shiva d'Ettore Sottsass",
    designer: "Ettore Sottsass",
    object: "Shiva",
    flux: "4AOgwvu20IS88p8jmwTDOb",
    links: {
      apple:
        "https://podcasts.apple.com/fr/podcast/le-vase-shiva-dettore-sottsass-%C3%A9pisode-6/id1601115221?i=1000549623705",
      spotify:
        "https://open.spotify.com/episode/4AOgwvu20IS88p8jmwTDOb?si=ARVZFVq5TZelP2tYPtBuAg",
      deezer: "https://deezer.page.link/v3bWaGrXXiXF2bwp8",
    },
    preview:
      "Je ne vais pas tourner autour du pot… ou du vase plutôt. Voici Shiva, un vase de couleur rose de forme phalique...",
    intro:
      "Je ne vais pas tourner autour du pot… ou du vase plutôt. Voici Shiva, un vase de couleur rose de forme phalique.Ironique, irrévérent et insolent, cet objet est tout à l’image de son créateur, Ettore Sottsass : ce designer-architecte qui manie avec dextérité, exubérance et humour l’art de la contradiction...<br/><br/>Ouvrez grands les oreilles. On va tenter de comprendre ce qui lui est passé par la tête.",
    content: "",
    images: {
      cover: "/images/podcast/saison01/episode06/objet.jpg",
      object: "/images/podcast/saison01/episode06/objet.jpg",
      designer: "/images/podcast/saison01/episode06/designer.jpg",
    },
    stories: [
      "shiva",
      "valigia",
      "ultrafragola",
      "tahiti",
      "valentine",
      "carlton",
    ],
    sagas: [],
  },
  {
    publishedAt: "2022-02-08",
    slug: "episode-7",
    season: "01 Design italien",
    episodeNumber: 7,
    title: "La cafetière Alessi 9090 de Richard Sapper",
    designer: "Richard Sapper",
    object: "9090",
    flux: "4yJmNojP2uhiAn5F1KjWq0",
    links: {
      apple:
        "https://podcasts.apple.com/fr/podcast/la-cafeti%C3%A8re-alessi-9090-de-richard-sapper-episode-7/id1601115221?i=1000550441940",
      spotify:
        "https://open.spotify.com/episode/4yJmNojP2uhiAn5F1KjWq0?si=Nr84x80-TBqwDKb7jCzLRw",
      deezer: "https://deezer.page.link/2GRedddJgtYB6sLV9",
    },
    preview:
      "On ne pouvait pas aborder le design italien sans parler de l’un des symboles du pays : la cafetière moka...",
    intro:
      "On ne pouvait pas aborder le design italien sans parler de l’un des symboles du pays : la cafetière moka. On a choisi la crème de la crème : la moka 9090 d’Alessi.<br/>C’est la première cafetière espresso de l’histoire d’Alessi. Elle qui leur permet de remporter leur premier Compasso d’Oro et aussi d’entrer au MoMA de New York pour la première fois. Ironie du sort, cette cafetière des plus italiennes est dessinée par un maestro allemand, Richard Sapper.<br/><br/>Andiamo, suivez-moi pour découvrir cette cafetière des premières fois…",
    content: "",
    images: {
      cover: "/images/podcast/saison01/episode07/objet.jpg",
      object: "/images/podcast/saison01/episode07/objet.jpg",
      designer: "/images/podcast/saison01/episode07/designer.jpg",
    },
    stories: [
      "thinkpad",
      "tizio",
      "annag",
      "bouilloire-9093",
      "coucou",
      "juicysalif",
      "bialetti",
    ],
    sagas: [],
  },
  {
    publishedAt: "2022-02-15",
    slug: "episode-8",
    season: "01 Design italien",
    episodeNumber: 8,
    title: "La lampe Pipistrello de Gae Aulenti",
    designer: "Gae Aulenti",
    object: "Pipistrello",
    flux: "56srS1HY7ywh9rMMp9I29I",
    links: {
      apple:
        "https://podcasts.apple.com/fr/podcast/la-lampe-pipistrello-de-gae-aulenti-%C3%A9pisode-8/id1601115221?i=1000551125314",
      spotify:
        "https://open.spotify.com/episode/56srS1HY7ywh9rMMp9I29I?si=3kRyWU5XTNSbhGn9BtU3VQ",
      deezer: "https://www.deezer.com/fr/episode/363395912",
    },
    preview:
      "C'est la star incontestée des intérieurs et des bureaux d'esthètes. Facile vous allez me dire...",
    intro:
      "C’est la star incontestée des intérieurs et des bureaux d'esthètes… Facile vous allez me dire : un style aux courbes organiques, du noir et blanc, c’est la recette assurée du succès.<br/>Mais cet objet est plus qu’une histoire de style, car sa designer Gae Aulenti est une créatrice à contre-courant.<br/><br/>Lumière sur cette lampe à l'esthétique surprenante pour l’époque qui fût un succès inattendu et qui n’a failli jamais voir le jour.",
    content: "",
    images: {
      cover: "/images/podcast/saison01/episode08/objet.jpg",
      object: "/images/podcast/saison01/episode08/objet.jpg",
      designer: "/images/podcast/saison01/episode08/designer.jpg",
    },
    stories: ["pipistrello", "tavoloconruote"],
    sagas: [],
  },
  {
    publishedAt: "2022-02-22",
    slug: "episode-9",
    season: "01 Design italien",
    episodeNumber: 9,
    title: "La chaise Autoprogettazione Sedia d'Enzo Mari",
    designer: "Enzo Mari",
    object: "Sedia",
    flux: "4TqyxUJfiRNYUIU8p1HQzK",
    links: {
      apple:
        "https://podcasts.apple.com/fr/podcast/la-chaise-autoprogettazione-sedia-denzo-mari-%C3%A9pisode-9/id1601115221?i=1000551847860",
      spotify:
        "https://open.spotify.com/episode/4TqyxUJfiRNYUIU8p1HQzK?si=jPI2nmxjS_C5oO71yzkRpg",
      deezer: "https://deezer.page.link/UQHiz8DayCx6GARq5",
    },
    preview:
      "Des tasseaux et quelques planches, des clous et un marteau. Il n’en faut pas plus pour construire cet objet...",
    intro:
      "Des tasseaux et quelques planches.<br/>Des clous.<br/>Un marteau.<br/><br/>Il n’en faut pas plus pour construire l’objet du jour.<br/>Et mieux que ça, son designer veut que vous le construisiez vous-même !<br/><br/>Aujourd’hui nous allons parler de la chaise Sedia d’Enzo Mari, issue du projet Autoprogettazione. <br/><br/>Un objet aussi emblématique pour son design que pour le message qu’il véhicule et la voie qu’il a ouverte.<br/><br/>Sortez les marteaux et les clous, nous allons construire ou plutôt déconstruire cette chaise et comprendre ce qu’elle nous cache !",
    content: "",
    images: {
      cover: "/images/podcast/saison01/episode09/objet.jpg",
      object: "/images/podcast/saison01/episode09/objet.jpg",
      designer: "/images/podcast/saison01/episode09/designer.jpg",
    },
    stories: ["muranod", "putrella", "16-animali", "sediap", "timor"],
    sagas: [],
  },
];

export default Podcast;
