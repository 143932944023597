import React from "react";
import { Helmet } from "react-helmet";

import Layout from "../../components/Layout";

import * as S from "./styles";

const Newsletter = () => (
  <Layout subFooter={false} newsletter={false}>
    <Helmet>
      <title>Punktional - La Newsletter</title>
    </Helmet>
    <S.Wrapper>
      <S.Content>
        <img src="/images/newsletter/cover-nl.png" alt="" />
        <h1>
          Tous les mardis, un digest design sauce Punktional, <br />
          avec supplément inspi 🍦
        </h1>
        <p>
          On décortique les sujets de design du moment en allant chercher les ressources les plus pertinentes et
          les bonnes idées. A la clé un digest à la sauce Punktional. Clair et concis sans être
          simpliste, au ton didactique mais cool, illustré et truffé
          d’anecdotes.
        </p>
        <p>
          5 minutes de lecture pour permettre d’apprendre, voire de se forger
          une opinion.
          <br />5 minutes de découverte. Car on s’est fixé un autre objectif :
          vous inspirer !
        </p>
        <S.Link
          to="#sabonner"
          scroll={(el) => el.scrollIntoView({ behavior: "auto", block: "end" })}
        >
          S'abonner
        </S.Link>
        <h2>Ce qu'en disent les lecteurs 💙</h2>

        <img src="/images/newsletter/mots-doux.jpg" alt="Feedbacks" />

        <h2>Qui écrit ?</h2>
        <p>
          Un duo passionné de design : Mathilde Maître et Thomas Borie qui
          orchestrent et rédigent tous les contenus de Punktional, à tour de
          rôle ou à quatre mains.
          <br /> <br />
          Mathilde est designer, diplômée de l’ENSCI-Les ateliers, tout comme
          Thomas qui est aussi ingénieur des Arts et Métiers. Ils se sont
          rencontrés sur les bancs d’une agence de conseil en innovation,
          Fabernovel.
          <br />
          Ils partagent de nombreux projets de conception de produits et de
          services, autant numérique qu'objet.
          <br />
          Punktional est un projet passion devenu media et studio.
        </p>

        <img src="/images/about/portrait.jpg" alt="About us" />

        <h2>Pourquoi la lire ?</h2>
        <dl>
          <dt>C’est une newsletter pour nous designers et passionnés de design, avec :</dt>
          <dd>Des histoires de design qui font et refont le monde</dd>
          <dd>De l’inspi bien sentie</dd>
          <dd>De nouveaux talents designers à découvrir</dd>
        </dl>
        <p>
          Les questions que nous abordons sont celles que nous nous posons. Nous
          vous faisons gagner du temps en sélectionnant les ressources
          pertinentes et en partageant une analyse illustrée. L'objectif est
          d'offrir un tour d’horizon du sujet et d'aiguiser votre regard sur le
          design.
        </p>

        <S.Form id="sabonner" />
      </S.Content>
    </S.Wrapper>
  </Layout>
);

export default Newsletter;
