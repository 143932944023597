import styled from "styled-components";

import FormDefault from "../../components/Newsletter/Form";

import { ButtonHash } from "../../components/commons/styles";
import * as C from "../../components/commons/styles";

export const Wrapper = styled(C.Content)`
  margin: 100px auto;

  img {
    display: block;
    margin: 0 auto;
    max-width: 650px;
  }

  @media (max-width: 768px) {
    margin: 20px 0;
    flex-direction: column-reverse;

    img {
      width: 100%;
    }
  }
`;

export const Content = styled.section`
  padding-right: 50px;
  font-size: 14px;
  line-height: 21px;
  color: ${({ theme }) => theme.blue};
  max-width: 650px;
  margin: 0 auto;

  strong,
  b {
    font-weight: bold;
  }

  p {
    font-size: 14px;
    line-height: 21px;
    & + p,
    & + img {
      margin-top: 20px;
    }

    a {
      color: inherit;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }

  h1 {
    margin: 40px 0 20px 0;
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
  }

  h2 {
    margin: 40px 0 20px 0;
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
  }

  dl {
    margin: 0 0 10px 0;
    dt {
      font-size: 14px;
      line-height: 21px;
      font-family: "Poppins", sans-serif;
      font-weight: 500;
    }
    dd {
      margin: 0 0 0 20px;
      display: list-item;
      list-style-type: disc;
      font-size: 14px;
      line-height: 21px;
      font-family: "Poppins", sans-serif;
      font-weight: 500;
    }
  }

  @media (max-width: 768px) {
    width: 100%;
    padding: 0 20px;
  }
`;

export const Link = styled(ButtonHash)`
  display: block;
  margin: 25px auto;
  width: fit-content;
  color: ${({ theme }) => theme.white};
  background: ${({ theme }) => theme.blue};

  &:hover {
    background: ${({ theme }) => theme.white};
    color: ${({ theme }) => theme.blue};
  }
`;

export const Form = styled(FormDefault)`
  margin-top: 40px;
  padding: 0;

  p {
    margin: 0 0 20px 0;
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    font-style: normal;
  }
`;

export const Episode = styled.article`
  display: flex;

  span {
    display: inline-block;
    margin-right: 40px;
    text-align: right;
    width: 80px;
  }

  a {
    color: ${({ theme }) => theme.blue};
    text-decoeation: underline;

    &:hover {
      text-decoration: none;
    }
  }

  & + & {
    margin-top: 20px;
  }

  & + ${Form} {
    margin-top: 100px;
  }
`;
