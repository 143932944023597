import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import Select from "react-select";

import AppContext from "../../../AppContext";

import * as S from "./styles";

const options = [
  { value: "objet mobilier", label: "Objet, mobilier" },
  { value: "design produit", label: "Design produit" },
  { value: "design service", label: "Design service, UX, UI" },
  { value: "design global", label: "Design global" },
  { value: "design textile", label: "Design textile, stylisme" },
  { value: "architecture ", label: "Architecture, espace " },
  { value: "graphisme", label: "Graphisme, illustration" },
  { value: "art", label: "Métiers d’art" },
  { value: "autres", label: "Autres" },
];

const Form = ({ className = "", id }) => {
  const { referrerId = "" } = useParams();
  const [email, setEmail] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);

  const config = useContext(AppContext);

  const handleSubmit = (e, subscribe) => {
    e.preventDefault();
    if (email && email.indexOf("@") > -1) {
      subscribe({
        EMAIL: email,
        DOMAIN: selectedOption?.value,
        REF_ID: referrerId,
      });
    }
  };

  return (
    <MailchimpSubscribe
      url={config.MAILCHIMP_URL}
      render={({ subscribe, status, message }) => (
        <S.Form className={className} id={id}>
          <S.Title>Pour la recevoir, abonnez-vous</S.Title>

          {status === "sending" && <div>Inscription en cours...</div>}
          {status === "error" && (
            <div
              style={{ color: "red" }}
              dangerouslySetInnerHTML={{ __html: message }}
            />
          )}
          {status === "success" && (
            <div>C'est enregistré. Rendez-vous mardi !</div>
          )}
          {!status && (
            <>
              <S.Label htmlFor="email">Votre e-mail</S.Label>
              <S.Input
                id="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Renseignez votre adresse"
              />

              <S.Label htmlFor="category">
                Votre terrain de jeu
                <span>
                  Cette info optionnelle nous permet de mieux vous connaître et
                  d'adapter nos contenus
                </span>
              </S.Label>
              <Select
                defaultValue={selectedOption}
                isSearchable={false}
                menuPortalTarget={document.body}
                onChange={setSelectedOption}
                options={options}
                placeholder="Sélectionnez un domaine"
                styles={{
                  container: (base) => ({
                    ...base,
                    borderColor: "#010023",
                  }),
                  control: (base) => ({
                    ...base,
                    borderColor: "#010023",
                    font: "300 16px Poppins, sans-serif",
                    boxShadow: "none",
                    "&:hover": { borderColor: "#010023" },
                  }),
                  menu: (base) => ({
                    ...base,
                    borderColor: "#010023",
                    font: "300 16px Poppins, sans-serif",
                  }),
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                }}
              />

              <S.Submit
                type="submit"
                onClick={(e) => handleSubmit(e, subscribe)}
              >
                S'abonner
              </S.Submit>
            </>
          )}
        </S.Form>
      )}
    />
  );
};

Form.propTypes = {
  id: PropTypes.string,
};

export default Form;
