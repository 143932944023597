import React from "react";
import { Helmet } from "react-helmet";
import { useIntl } from "react-intl";
import { isMobile } from "react-device-detect";

import Layout from "../../components/Layout";

import Newsletter from "../../components/Newsletter";
import Next from "../../components/Next/Home";
import Podcast from "../../components/Podcast/Home";
import Sagas from "../../components/Saga/Home";
import Stories from "../../components/Stories/Home";

import * as S from "./styles";

const Home = () => {
  const intl = useIntl();

  return (
    <Layout subFooter={false} newsletter={false}>
      <Helmet>
        <title>Punktional</title>
      </Helmet>

      <S.Wrapper>
        <S.Intro>
          <p>
            {intl.formatMessage(
              { id: "home.intro.text" },

              {
                br: <br />,
                b: (...chunk) => <strong>{chunk}</strong>,
              }
            )}
          </p>
        </S.Intro>

        <S.Podcast>
          <Podcast />
        </S.Podcast>

        <S.Stories>
          <Stories />
        </S.Stories>

        <S.Newsletter>
          <Newsletter />
        </S.Newsletter>

        <S.Sagas>
          <Sagas />
        </S.Sagas>

        <S.Next>
          <Next />
        </S.Next>
      </S.Wrapper>
    </Layout>
  );
};

export default Home;
