import React, { useContext } from "react";
import PropTypes from "prop-types";
import { ThemeContext } from "styled-components";

const Delete = ({ color }) => {
  const theme = useContext(ThemeContext);
  const fillColor = color ? color : theme.blue;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 40" x="0" y="0">
      <path
        d="M25.19,6.81a13,13,0,1,0,0,18.38A13,13,0,0,0,25.19,6.81ZM21,19.54A1,1,0,0,1,21,21a1,1,0,0,1-.71.29,1,1,0,0,1-.7-.29L16,17.41,12.46,21a1,1,0,0,1-.7.29,1,1,0,0,1-.71-.29,1,1,0,0,1,0-1.41L14.59,16l-3.54-3.54a1,1,0,0,1,1.41-1.41L16,14.59l3.54-3.54A1,1,0,0,1,21,12.46L17.41,16Z"
        fill={fillColor}
      />
    </svg>
  );
};

Delete.propTypes = {
  color: PropTypes.string,
};

export default Delete;
