import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";

import Item from "../Item";

import * as S from "./styles";

const List = ({ episodes }) => {
  const intl = useIntl();

  return (
    <S.Wrapper>
      {episodes.map((elt, i) => (
        <Item key={`episode-${elt.slug}`} episode={elt} />
      ))}
    </S.Wrapper>
  );
};

List.propTypes = {
  episodes: PropTypes.array.isRequired,
};

export default List;
