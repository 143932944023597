import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { rgba } from "polished";

import ListenBackground from "./listen-on-embed.svg";

export const GoBack = styled(Link)`
  margin: 20px 0 0 20px;
  text-decoration: none;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  color: ${({ theme }) => theme.white};
  transition: color 0.2s;

  &:hover {
    color: ${({ theme }) => rgba(theme.white, 0.39)};
  }

  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  margin: 50px 50px 40px 50px;
  flex-direction: column;
  background: ${({ theme }) => theme.white};
  border: 1px solid rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    margin: 50px 20px;
  }
`;

export const Header = styled.div`
  display: flex;
`;

export const Visu = styled.div`
  flex: 1 0 50%;
  img {
    width: 100%;
  }
`;

export const Content = styled.div`
  padding: 35px 100px;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

export const Metas = styled.div`
  display: flex;
`;

export const Season = styled.div`
  text-transform: uppercase;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
`;

export const Date = styled.div`
  margin: 0 0 0 20px;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: ${({ theme }) => rgba(theme.blue, 0.5)};
`;

export const Title = styled.div`
  margin: 10px 0 5px 0;
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;

  @media (max-width: 768px) {
    font-size: 18px;
    line-height: 27px;
  }
`;

export const Intro = styled.div`
  margin-top: 40px;
  font-size: 16px;
  line-height: 24px;
`;

export const Player = styled.div`
  margin: 40px 0;
`;

export const Description = styled.div`
  font-size: 16px;
  line-height: 24px;
`;

export const Objects = styled.section`
  margin-top: 50px;
  padding: 0 35px;
  @media (max-width: 768px) {
    padding: 0 20px;
  }
`;

export const SubTitle = styled.h2`
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;

  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const Items = styled.section`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 40px;
  margin-top: 40px;

  @media (max-width: 1280px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);

    a {
      margin-bottom: 0;
    }
  }
`;

export const ButtonListen = styled.a`
  display: inline-block;
  background-color: #ffffff;
  background-image: url(${ListenBackground});
  background-repeat: no-repeat;
  border: 1px solid #000000;
  border-radius: 5px;
  margin: 0 5px 5px 0;
  text-indent: -9000px;
  background-position: -230px 7px;
  height: 40px;
  width: 165px;
  cursor: pointer;

  & + a {
    margin-left: 20px;

    @media (max-width: 768px) {
      margin: 10px 0 0 0;
    }
  }

  ${(props) =>
    props.kind === "spotify" &&
    css`
      background-position: -230px -53px;
    `}

  ${(props) =>
    props.kind === "deezer" &&
    css`
      background-position: -230px -533px;
    `}
`;
