import React, { useContext } from "react";
import PropTypes from "prop-types";
import { ThemeContext } from "styled-components";

const Chat = ({ color }) => {
  const theme = useContext(ThemeContext);
  const fillColor = color ? color : theme.blue;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
      <path
        fill={fillColor}
        d="M31.5,9.2H12.3c-4.5,0-8.1,3.6-8.1,8.1v9.9c0,0.5-0.1,0.9-0.3,1.3l-3.7,6.7c-0.2,0.4-0.2,0.9,0,1.3c0.2,0.4,0.7,0.7,1.2,0.7 h30.2c4.5,0,8.1-3.6,8.1-8.1V17.2C39.6,12.8,36,9.2,31.5,9.2z M37.9,29.2c0,3.5-2.9,6.4-6.4,6.4H1.9l3.4-6.2 c0.4-0.7,0.6-1.4,0.6-2.2v-9.9c0-3.5,2.9-6.4,6.4-6.4h19.2c3.5,0,6.4,2.9,6.4,6.4V29.2z M30.2,18.3c0,1-0.8,1.9-1.9,1.9 c-1,0-1.9-0.8-1.9-1.9c0-1,0.8-1.9,1.9-1.9C29.3,16.5,30.2,17.3,30.2,18.3z M16.7,20.2c-1,0-1.9-0.8-1.9-1.9c0-1,0.8-1.9,1.9-1.9 s1.9,0.8,1.9,1.9C18.6,19.4,17.8,20.2,16.7,20.2z M27.6,24H17.5c-0.5,0-0.8,0.4-0.8,0.8c0,3.3,2.6,6,5.9,6s5.9-2.7,5.9-6 C28.4,24.3,28,24,27.6,24z M22.5,29.1c-2,0-3.8-1.5-4.1-3.5h8.3C26.3,27.6,24.6,29.1,22.5,29.1z M42.8,31.1c0-0.5,0.4-0.8,0.8-0.8  h5.5c0.5,0,0.8,0.4,0.8,0.8s-0.4,0.8-0.8,0.8h-5.5C43.2,31.9,42.8,31.6,42.8,31.1z M49.7,40.3c-0.1,0.3-0.4,0.5-0.8,0.5 c-0.1,0-0.2,0-0.3-0.1l-5.3-2.1c-0.4-0.2-0.6-0.7-0.5-1.1c0.2-0.4,0.7-0.6,1.1-0.5l5.3,2.1C49.7,39.4,49.9,39.9,49.7,40.3z M42.9,24.7c-0.2-0.4,0-0.9,0.5-1.1l5.3-2.1c0.4-0.2,0.9,0,1.1,0.5c0.2,0.4,0,0.9-0.5,1.1L44,25.1c-0.1,0-0.2,0.1-0.3,0.1 C43.3,25.2,43,25,42.9,24.7z"
      />
    </svg>
  );
};

Chat.propTypes = {
  color: PropTypes.string,
};

export default Chat;
