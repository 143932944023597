import React from "react";
import { useIntl } from "react-intl";

import Logo from "../../images/Logo";

import * as S from "./styles";

const Home = () => {
  const intl = useIntl();

  return (
    <S.Wrapper>
      <S.Left>
        <div>
          <S.Logo>
            <Logo />
          </S.Logo>

          <S.Intro>
            {intl.formatMessage(
              { id: "next.home.intro" },
              {
                br: <br />,
                b: (...chunk) => <strong>{chunk}</strong>,
              }
            )}
          </S.Intro>
        </div>
      </S.Left>

      <S.Right>
        <S.Title>{intl.formatMessage({ id: "next.home.title" })}</S.Title>

        <S.Content>
          {intl.formatMessage(
            { id: "next.home.content" },
            {
              br: <br />,
              b: (...chunk) => <strong>{chunk}</strong>,
            }
          )}
        </S.Content>

        <S.Footer>
          <S.Link as="a" href="mailto:hello@punktional.com">
            {intl.formatMessage({ id: "next.home.button" })}
          </S.Link>
        </S.Footer>
      </S.Right>
    </S.Wrapper>
  );
};

export default Home;
