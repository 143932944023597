import { params2Obj } from "./utils";

export const fromUrl = () => {
  const params = params2Obj(window.location.search);
  return params.locale || params.lang;
};

export const getLanguage = () => {
  const language =
    window.localStorage.getItem("i18n") ||
    fromUrl() ||
    (navigator.languages && navigator.languages[0]) ||
    navigator.language ||
    navigator.userLanguage;

  // Split locales with a region code
  return language.toLowerCase().split(/[_-]+/)[0];
};
