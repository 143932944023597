export const obj2Params = (obj) =>
  Object.entries(obj)
    .reduce((acc, [key, val]) => {
      if (val)
        acc.push(`${encodeURIComponent(key)}=${encodeURIComponent(val)}`);
      return acc;
    }, [])
    .join("&");

export const params2Obj = (str) => {
  const params = str.slice(str.indexOf("?") + 1).split("&");
  return params.reduce((acc, cu) => {
    const [key, value] = cu.split("=");
    if (key && value) acc[key] = value;
    return acc;
  }, {});
};

export const Capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);
