import React, { useContext } from "react";
import { ThemeContext } from "styled-components";

const Logo = ({ color }) => {
  const theme = useContext(ThemeContext);
  const fillColor = color ? color : theme.blue;

  return (
    <svg
      width="43"
      height="25"
      viewBox="0 0 43 25"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          fill={fillColor}
          d="M10.5051 3.06654C11.0361 3.40403 11.4559 3.88714 11.7642 4.51586C12.0709 5.14459 12.2251 5.88981 12.2251 6.7481C12.2251 7.59782 12.0709 8.33962 11.7642 8.97348C11.4559 9.60906 11.0379 10.0939 10.5051 10.4297C9.97228 10.7671 9.37096 10.935 8.69769 10.935C8.07239 10.935 7.53618 10.7928 7.08733 10.5102C6.63849 10.2275 6.31128 9.84205 6.1057 9.3538V14.7262H3.60107V2.66395H6.10399V4.1424C6.30956 3.65415 6.63506 3.26869 7.08562 2.98602C7.53446 2.70335 8.07068 2.56116 8.69598 2.56116C9.37096 2.56287 9.97228 2.73076 10.5051 3.06654ZM6.59566 5.29192C6.25988 5.64826 6.09028 6.13308 6.09028 6.7481C6.09028 7.35284 6.25817 7.83595 6.59566 8.19742C6.93315 8.5589 7.36486 8.73878 7.8908 8.73878C8.42702 8.73878 8.85873 8.56232 9.18594 8.21113C9.51315 7.85993 9.6759 7.37169 9.6759 6.74639C9.6759 6.12108 9.51315 5.63284 9.18594 5.28164C8.85873 4.93044 8.42702 4.75399 7.8908 4.75399C7.36486 4.75913 6.93315 4.93558 6.59566 5.29192Z"
        />
        <path
          fill={fillColor}
          d="M21.6543 2.66396V10.834H19.136V9.35552C18.9013 9.83349 18.5518 10.2138 18.0893 10.4982C17.625 10.7809 17.0871 10.9231 16.472 10.9231C15.5349 10.9231 14.7897 10.6113 14.2398 9.98596C13.6882 9.36066 13.4124 8.50237 13.4124 7.40938V2.66396H15.9016V7.10102C15.9016 7.65779 16.0455 8.0895 16.3333 8.39616C16.6211 8.70452 17.0083 8.85699 17.4965 8.85699C18.0036 8.85699 18.4045 8.69596 18.6974 8.37389C18.9904 8.05181 19.136 7.59269 19.136 6.99823V2.66396H21.6543Z"
        />
        <path
          fill={fillColor}
          d="M30.7973 3.51368C31.349 4.13898 31.6248 4.99726 31.6248 6.09025V10.834H29.1219V6.3832C29.1219 5.83671 28.978 5.41013 28.6902 5.10177C28.4023 4.7934 28.0186 4.64093 27.5406 4.64093C27.0335 4.64093 26.6309 4.80197 26.3329 5.12404C26.0348 5.44611 25.8857 5.90523 25.8857 6.4997V10.834H23.3828V2.66395H25.8857V4.15782C26.1204 3.66957 26.4682 3.28411 26.9325 3.00145C27.395 2.71878 27.9398 2.57658 28.5651 2.57658C29.5005 2.57658 30.2457 2.89009 30.7973 3.51368Z"
        />
        <path
          fill={fillColor}
          d="M38.2274 10.834L35.7827 7.36483V10.834H33.2798V0H35.7827V5.94463L38.2274 2.66566H41.1706L37.8762 6.74981L41.2442 10.834H38.2274Z"
        />
        <path
          fill={fillColor}
          d="M5.25595 22.7455V24.8972H4.12869C3.17275 24.8972 2.42753 24.6608 1.89646 24.188C1.36367 23.7151 1.09813 22.9305 1.09813 21.8375V18.8361H0V16.7289H1.09813V14.7228H3.60104V16.7289H5.24053V18.8378H3.60104V21.8838C3.60104 22.2059 3.66614 22.4303 3.79805 22.5571C3.92997 22.6838 4.15096 22.7472 4.46447 22.7472H5.25595V22.7455Z"
        />
        <path
          fill={fillColor}
          d="M6.83718 13.4859C7.10957 13.2375 7.47104 13.1125 7.91989 13.1125C8.36873 13.1125 8.73021 13.2375 9.0026 13.4859C9.27499 13.7343 9.41204 14.0496 9.41204 14.4299C9.41204 14.7999 9.27499 15.11 9.0026 15.3601C8.72849 15.6085 8.36873 15.7336 7.91989 15.7336C7.47104 15.7336 7.10957 15.6085 6.83718 15.3601C6.56307 15.1117 6.42773 14.8016 6.42773 14.4299C6.42773 14.0496 6.56307 13.7343 6.83718 13.4859ZM9.16535 16.7289V24.8989H6.66072V16.7289H9.16535Z"
        />
        <path
          fill={fillColor}
          d="M16.8882 17.1315C17.5272 17.469 18.0308 17.9538 18.3957 18.5877C18.7624 19.2216 18.9457 19.9633 18.9457 20.8131C18.9457 21.6628 18.7624 22.4046 18.3957 23.0384C18.0291 23.6723 17.5272 24.1588 16.8882 24.4946C16.2492 24.8321 15.5245 25 14.7142 25C13.9039 25 13.1775 24.8321 12.5333 24.4946C11.8892 24.1571 11.3838 23.6723 11.0172 23.0384C10.6506 22.4046 10.469 21.6628 10.469 20.8131C10.469 19.9633 10.6523 19.2216 11.0172 18.5877C11.3838 17.9538 11.8892 17.4673 12.5333 17.1315C13.1775 16.794 13.9039 16.6261 14.7142 16.6261C15.5245 16.6261 16.2492 16.794 16.8882 17.1315ZM13.5064 19.3123C13.1792 19.6584 13.0164 20.1586 13.0164 20.8131C13.0164 21.4675 13.1792 21.9643 13.5064 22.3069C13.8336 22.6479 14.2362 22.8192 14.7142 22.8192C15.1921 22.8192 15.593 22.6479 15.9151 22.3069C16.2372 21.966 16.3982 21.4675 16.3982 20.8131C16.3982 20.1586 16.2372 19.6584 15.9151 19.3123C15.593 18.9663 15.1921 18.7933 14.7142 18.7933C14.2362 18.7933 13.8336 18.9663 13.5064 19.3123Z"
        />
        <path
          fill={fillColor}
          d="M27.664 17.5769C28.2157 18.2022 28.4915 19.0605 28.491 20.1535V24.8972H25.9886V20.4465C25.9886 19.9 25.8447 19.4734 25.5568 19.165C25.269 18.8567 24.8853 18.7042 24.4073 18.7042C23.9002 18.7042 23.4976 18.8652 23.1996 19.1873C22.9015 19.5094 22.7524 19.9685 22.7524 20.563V24.8972H20.2495V16.7289H22.7524V18.2228C22.9871 17.7345 23.3349 17.3491 23.7992 17.0664C24.2617 16.7837 24.8065 16.6415 25.4318 16.6415C26.3672 16.6398 27.1124 16.9533 27.664 17.5769Z"
        />
        <path
          fill={fillColor}
          d="M34.8592 17.051C35.308 17.3337 35.6404 17.7191 35.8545 18.2074V16.7289H38.3437V24.8989H35.8545V23.4205C35.6404 23.9087 35.308 24.2942 34.8592 24.5769C34.4103 24.8595 33.8724 25.0017 33.2488 25.0017C32.5755 25.0017 31.9725 24.8338 31.4414 24.4963C30.9086 24.1588 30.4906 23.674 30.1823 23.0402C29.8739 22.4063 29.7214 21.6645 29.7214 20.8148C29.7214 19.9565 29.8756 19.2113 30.1823 18.5825C30.4906 17.9538 30.9086 17.469 31.4414 17.1332C31.9725 16.7957 32.5755 16.6278 33.2488 16.6278C33.8741 16.6261 34.4103 16.7666 34.8592 17.051ZM32.7588 19.3483C32.4316 19.6995 32.2689 20.1878 32.2689 20.8131C32.2689 21.4384 32.4316 21.9249 32.7588 22.2761C33.0861 22.6273 33.5178 22.8037 34.054 22.8037C34.5816 22.8037 35.0134 22.6239 35.3491 22.2624C35.6866 21.9009 35.8545 21.4178 35.8545 20.8131C35.8545 20.198 35.6866 19.7132 35.3491 19.3569C35.0116 19.0006 34.5799 18.8224 34.054 18.8224C33.5178 18.8224 33.0861 18.9971 32.7588 19.3483Z"
        />
        <path
          fill={fillColor}
          d="M42.5752 14.0632V24.8972H40.0723V14.0632H42.5752Z"
        />
      </g>
    </svg>
  );
};

export default Logo;
