import styled from "styled-components";

export const Wrapper = styled.section`
  padding-bottom: 100px;
  h2 {
    margin-bottom: 32px;
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    line-height: 72px;

    span {
      font-size: 24px;
      line-height: 36px;
    }
  }

  @media (max-width: 768px) {
    h2 {
      font-weight: bold;
      font-size: 14px;
      line-height: 21px;
      span {
        display: block;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
      }
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;

  p {
    margin: 0;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
  }

  a {
    color: inherit;
    &:before {
      text-decoration: none;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;

    p {
      font-size: 12px;
      line-height: 18px;
    }
  }
`;

export const Img = styled.img`
  width: 300px;
  height: 100%;
  margin-right: 65px;

  @media (max-width: 768px) {
    width: 100%;
    margin: 0 0 20px 0;
  }
`;
