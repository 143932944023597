import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { isMobile } from "react-device-detect";
import Stories from "react-insta-stories";

import * as S from "./styles";

const History = ({ history }) => {
  const intl = useIntl();

  return (
    <S.Wrapper>
      <S.Center>
        <h2>
          {intl.formatMessage(
            { id: `history.title${isMobile ? ".mobile" : ""}` },
            {
              span: (...chunk) => <span>{chunk}</span>,
              a: (...chunk) => (
                <a
                  href="https://www.instagram.com/punktional_/"
                  target="_blank"
                  rel="nofollow"
                >
                  {chunk}
                </a>
              ),
            }
          )}
        </h2>

        {!isMobile && (
          <S.Content>
            <p dangerouslySetInnerHTML={{ __html: history.resume }} />

            <S.Stories>
              <S.List>
                <Stories
                  stories={history.stories}
                  defaultInterval={3000}
                  loop={true}
                />
              </S.List>
            </S.Stories>
          </S.Content>
        )}
      </S.Center>
    </S.Wrapper>
  );
};

History.propTypes = {
  history: PropTypes.object.isRequired,
};

export default History;
