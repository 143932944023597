import React, { useEffect, useState } from "react";
import { Route, useLocation, Switch } from "react-router-dom";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import AppContext from "./AppContext";

import ScrollToTop from "./components/ScrollToTop";
import * as Pages from "./pages/index";

const ROUTES = [
  { path: "/", exact: true, component: Pages.Home, open: true },

  { path: "/a-propos", exact: true, component: Pages.About, open: true },
  {
    path: "/stories/:slug?",
    exact: false,
    component: Pages.Stories,
    open: true,
  },
  {
    path: "/newsletter/episodes",
    exact: true,
    component: Pages.NewsletterEpisodes,
    open: true,
  },
  {
    path: "/newsletter/:referrerId?",
    exact: true,
    component: Pages.Newsletter,
    open: true,
  },
  {
    path: "/parrainage/:referrerId?",
    exact: true,
    component: Pages.ReferralProgram,
    open: true,
  },
  {
    path: "/saga/:slug?",
    exact: true,
    component: Pages.Saga,
    open: true,
  },
  {
    path: "/podcast/feedback",
    exact: true,
    component: Pages.GForm,
    open: true,
  },
  {
    path: "/podcast/:slug?",
    exact: true,
    component: Pages.Podcast,
    open: true,
  },
  {
    path: "/objets-de-convoitise",
    exact: true,
    component: Pages.Study,
    open: true,
  },

  { path: "*", component: Pages.NotFound },
];

const API_URL = process.env.API_URL ? process.env.API_URL : "/api";

const App = () => {
  const [config, setConfig] = useState({
    GA_ID: process.env.GA_ID,
    MAILCHIMP_URL: process.env.MAILCHIMP_URL,
    API_URL: process.env.API_URL || "/api",
    ENV: process.env.ENV || "development",
  });

  const location = useLocation();

  useEffect(() => {
    const loadConfig = async () => {
      const response = await fetch(`${config.API_URL}/config`, {
        method: "GET",
        mode: "cors",
        redirect: "follow",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      setConfig({ ...config, ...data });
    };

    loadConfig();
  }, []);

  useEffect(() => {
    if (config.GA_ID) ReactGA.initialize(config.GA_ID);
  }, [config.GA_ID]);

  useEffect(() => {
    if (config.GA_ID) {
      ReactGA.pageview(location.pathname);
    }
  }, [location]);

  useEffect(() => {
    Sentry.init({
      dsn: config.SENTRY,
      integrations: [new Integrations.BrowserTracing()],
      environment: config.ENV,

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,
    });
  }, [config.SENTRY]);

  return (
    <AppContext.Provider value={config}>
      <Helmet>
        <meta name="title" content="Punktional" />
        <meta
          name="description"
          content="Punktional_ le compte instagram sur le design qui raconte les objets iconiques et leurs designers. 10 stories et 1 post, tous les lundis et jeudis 7:30 sur instagram."
        />
      </Helmet>

      <ScrollToTop />
      <Switch>
        {ROUTES.filter((elt) => elt.open).map(
          ({ path, exact, component: Comp }) => (
            <Route
              path={path}
              exact={exact}
              strict={false}
              sensitive={false}
              key={path}
              render={() => <Comp />}
            />
          )
        )}
      </Switch>
    </AppContext.Provider>
  );
};

export default App;
