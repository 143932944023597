import styled from "styled-components";
import { rgba } from "polished";

export const Object = styled.h1``;
export const Designer = styled.h2``;
export const Date = styled.p``;

export const Hover = styled.div`
  display: none;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => rgba(theme.blue, 0.39)};

  svg {
    width: 20%;
  }
`;

export const Wrapper = styled.a`
  margin-bottom: 45px;
  color: ${({ theme }) => theme.blue};
  text-decoration: none;

  ${Object}, ${Designer}, ${Date} {
    margin: 0;
    font-size: 14px;
    line-height: 21px;
    font-weight: bold;
    text-align: left;
  }

  ${Object} {
    margin-top: 10px;
    font-style: italic;
  }

  &:hover {
    ${Hover} {
      display: flex;
    }
  }
`;

export const Preview = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  overflow: hidden;
￼ align-items: center;
  cursor: pointer;
  flex-direction: column;

  img {
    width: 100%;
    margin-top: -6px;
  }

  @media (max-width: 768px) {
    width: 100%;
    height: auto;
  }
`;
