import React from "react";
import { Helmet } from "react-helmet";
import { useIntl } from "react-intl";

import Layout from "../../components/Layout";

import * as S from "./styles";

const GForm = () => {
  return (
    <Layout subFooter={false} newsletter={false}>
      <Helmet>
        <title>Punktional - Podcast Feedback</title>
      </Helmet>
      <S.Wrapper>
        <iframe
          src="https://docs.google.com/forms/d/e/1FAIpQLScnHd3lmu9fDOC2MG90gsyCNmByxnM3CEb1ruvT5gjwgtHqtA/viewform?usp=sf_link"
          width="640"
          height="2970"
          frameborder="0"
          marginheight="0"
          marginwidth="0"
        >
          Chargement…
        </iframe>
      </S.Wrapper>
    </Layout>
  );
};

export default GForm;
