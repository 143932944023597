import React, { useContext } from "react";
import PropTypes from "prop-types";
import { ThemeContext } from "styled-components";

const Eye = ({ color }) => {
  const theme = useContext(ThemeContext);
  const fillColor = color ? color : theme.blue;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
      <path
        fill={fillColor}
        d="M39.2,52.7L39.2,52.7l9.3,9.3c0.8,0.8,2.1,0.8,2.9,0l9.3-9.3l0,0c0,0,0,0,0,0c2.8-2.8,2.8-7.4,0-10.2     c-2.8-2.8-7.4-2.8-10.2,0c0,0,0,0,0,0l0,0L50,43l-0.6-0.6l0,0c0,0,0,0,0,0c-2.8-2.8-7.4-2.8-10.2,0     C36.4,45.3,36.4,49.8,39.2,52.7C39.2,52.7,39.2,52.7,39.2,52.7z"
      />
      <path
        fill={fillColor}
        d="M96.8,48.1c-0.9-1-21.1-23.6-46.8-23.6S4.1,47.2,3.2,48.1c-0.9,1.1-0.9,2.7,0,3.7c0.9,1,21.1,23.6,46.8,23.6     s45.9-22.6,46.8-23.6C97.7,50.8,97.7,49.2,96.8,48.1z M69.8,50c0,10.9-8.9,19.8-19.8,19.8S30.2,60.9,30.2,50S39.1,30.2,50,30.2     S69.8,39.1,69.8,50z M29,35.7c-2.8,4.1-4.4,9-4.4,14.3c0,5.3,1.6,10.2,4.4,14.3C19.6,59.6,12.4,53.2,9.3,50     C12.4,46.8,19.6,40.4,29,35.7z M71,64.3c2.8-4.1,4.4-9,4.4-14.3c0-5.3-1.6-10.2-4.4-14.3c9.3,4.7,16.5,11.2,19.7,14.3     C87.6,53.2,80.4,59.6,71,64.3z"
      />
    </svg>
  );
};

Eye.propTypes = {
  color: PropTypes.string,
};

export default Eye;
