import styled from "styled-components";
import { ButtonHash } from "../../components/commons/styles";
import * as C from "../../components/commons/styles";

export const Wrapper = styled(C.Content)`
  display: block;
  margin: 100px auto;
  max-width: 100%;

  h1 {
    margin-bottom: 30px;
    font-style: italic;
    font-weight: bold;
    font-size: 36px;
    line-height: 54px;
    text-align: center;
  }

  @media (max-width: 768px) {
    margin: 20px 0;
    flex-direction: column-reverse;

    h1 {
      font-size: 18px;
      line-height: 24px;
    }
  }
`;

export const Prices = styled.section`
  padding: 22px 0;
  background: ${({ theme }) => theme.blue};

  h2 {
    margin: 0 0 16px 0;
    font-size: 20px;
    line-height: 30px;
    color: ${({ theme }) => theme.white};
  }

  @media (max-width: 768px) {
    padding: 22px;
    h2 {
      font-weight: bold;
      font-size: 18px;
      line-height: 24px;
    }
  }
`;

export const PricesContent = styled.div`
  max-width: 1160px;
  margin: 0 auto;
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const Price = styled.article`
  display: flex;
  flex-direction: row;
  margin: 8px 0;
  padding: 0 0 0 25px;
  align-items: center;
  justify-content: center;

  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  color: ${({ theme }) => theme.white};
`;

export const Counter = styled.div`
  width: 55px;
  height: 55px;
  margin: 0 20px 0 0;
  border-radius: 50%;
  font-size: 20px;
  line-height: 55px;
  text-align: center;
  color: ${({ theme }) => theme.blue};
  background: ${({ theme }) => theme.white};
`;

export const Content = styled.section`
  padding-right: 50px;
  font-size: 14px;
  line-height: 21px;
  color: ${({ theme }) => theme.blue};
  max-width: 1160px;
  margin: 0 auto;

  h2 {
    margin: 40px 0 20px 0;
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;
  }

  li {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;

    & + li {
      margin-top: 45px;
    }
  }

  @media (max-width: 768px) {
    width: 100%;
    padding: 0 20px;
  }
`;

export const Form = styled.div`
  display: block;
  margin: 25px;

  @media (max-width: 768px) {
    margin: 25px 0;
  }
`;

export const Input = styled.input`
  width: 370px;
  margin-right: 5px;
  padding: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: ${({ theme }) => theme.blue};
  background: none;
  border: 1px solid ${({ theme }) => theme.blue};
  cursor: pointer;
  text-decoration: none;
`;
export const CopyLink = styled(ButtonHash)`
  display: inline-block;
  width: 170px;
  color: ${({ theme }) => theme.white};
  background: ${({ theme }) => theme.blue};
  text-align: center;

  &:hover {
    background: ${({ theme }) => theme.white};
    color: ${({ theme }) => theme.blue};
  }

  @media (max-width: 768px) {
    margin-top: 10px;
  }
`;

export const Copy = styled.div`
  margin-top: 5px;
  padding: 20px;
  font-size: 16px;
  font-weight: normal;
  line-height: 24px;

  border: 1px solid ${({ theme }) => theme.blue};
`;

export const CopyMsg = styled(ButtonHash)`
  display: inline-block;
  width: 220px;
  margin-top: 10px;
  text-align: center;
`;

export const Link = styled(ButtonHash)`
  display: block;
  margin: 25px;
  width: fit-content;
  color: ${({ theme }) => theme.white};
  background: ${({ theme }) => theme.blue};

  &:hover {
    background: ${({ theme }) => theme.white};
    color: ${({ theme }) => theme.blue};
  }
`;
