import styled from "styled-components";
import { Link as unstyledLink } from "react-router-dom";

import { ButtonLink } from "../../commons/styles";

export const Wrapper = styled.section`
  display: inline-block;
  flex: 1;
  max-width: 1200px;
  margin: 0 auto;

  @media (max-width: 1280px) {
    padding: 0 35px;
  }

  @media (max-width: 768px) {
    padding: 30px 35px;
    background: rgba(1, 0, 35, 0.02);
  }
`;

export const Title = styled.h1`
  margin-bottom: 40px;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.blue};

  @media (max-width: 768px) {
    margin-bottom: 30px;
    font-size: 12px;
    line-height: 18px;
  }
`;

export const Episodes = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const EpisodeHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const EpisodeTitle = styled.h2`
  font-size: 16px;
  line-height: 24px;
  font-weight: normal;

  @media (max-width: 768px) {
    font-size: 12px;
    line-height: 18px;
  }
`;

export const EpisodeSubTitle = styled.h3`
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 12px;
    line-height: 18px;
  }
`;

export const Img = styled.img`
  width: 200px;
  height: 200px;
  margin: 0 0 0 20px;
  object-fit: cover;
  object-position: left;
`;

export const Episode = styled(unstyledLink)`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 0 0 20px 0;
  padding: 0 10px;
  text-decoration: none;
  color: ${({ theme }) => theme.blue};
  text-align: right;

  &:hover {
    cursor: pointer;
  }

  &:nth-child(2n) {
    flex-direction: row-reverse;
    background: rgab(255, 0, 0, 0.2);
    text-align: left;

    ${Img} {
      margin: 0 20px 0 0;
    }
  }

  @media (max-width: 768px) {
    flex-direction: row-reverse;
    padding: 0;
    text-align: left;

    ${Img} {
      width: 150px;
      height: 150px;
      margin: 0 20px 0 0;
    }
  }
`;

export const Footer = styled.div`
  margin-top: 50px;
  text-align: center;

  @media (max-width: 768px) {
    margin-top: 30px;
  }
`;

export const Link = styled(ButtonLink)`
  display: inline-block;
  color: ${({ theme }) => theme.blue};
  border-color: ${({ theme }) => theme.blue};

  &:hover {
    background: ${({ theme }) => theme.blue};
    color: ${({ theme }) => theme.white};
  }
`;
