import styled from "styled-components";

export const Wrapper = styled.section`
  display: flex;
  margin-top: 90px;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    margin-top: 50px;
  }
`;

export const Image = styled.article`
  display: flex;
  flex: 1 0 50%;
  max-width: 50%;
  margin-right: 0px;
  margin-bottom: 40px;
  padding: 0 20px;
  flex-direction: column;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &.wide {
    flex: none;
    width: 100%;
    max-width: 100%;
    margin-right: 0;
    img {
      width: 100%;
      height: 100%;
    }
  }

  p {
    margin: 10px auto 0 auto;
    font-style: italic;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
  }

  @media (max-width: 768px) {
    flex: 1 0 45%;
    max-width: 45%;
    margin-right: 20px;
    margin-bottom: 20px;
    &:nth-child(2n) {
      margin-right: 0;
    }

    img {
      width: 100%;
      height: 100%;
    }

    p {
      display: none;
    }
  }
`;
