import styled from "styled-components";

import * as C from "../../commons/styles";

export const Wrapper = styled.section`
  color: ${({ theme }) => theme.white};
  background: ${({ theme }) => theme.blue};

  h2 {
    margin-bottom: 32px;
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    line-height: 72px;

    span {
      font-size: 24px;
      line-height: 36px;
    }
    a {
      text-decoration: none;
      color: inherit;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  @media (max-width: 1280px) {
    padding: 0 35px;
  }
  @media (max-width: 768px) {
    padding: 0;
    h2 {
      margin: 0;
      font-weight: bold;
      font-size: 14px;
      line-height: 21px;
      span {
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
      }
    }
  }
`;

export const Center = styled(C.Content)`
  flex-direction: column;
  padding: 40px 0 100px 0;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 30px 40px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;

  p {
    flex: 1;
    margin: 0 130px 0 0;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
  }
`;

export const Stories = styled.div`
  flex: 1;
  text-align: center;
`;

export const List = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 30px;
`;
